import { useState } from "react";
import { useMutation } from "react-query";
import { useAuth } from "../../context/AuthContext";
import { sendOtp, verifyOtp } from "../../services/loginApi";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const sendOtpMutation = useMutation(sendOtp, {
    onSuccess: () => {
      setIsOtpSent(true);
      setSuccessMessage(`OTP sent to ${mobileNumber}`);
      setError("");
    },
    onError: () => {
      setError("Failed to send OTP. Please try again.");
      setSuccessMessage("");
    },
  });

  const verifyOtpMutation = useMutation(verifyOtp, {
    onSuccess: (data) => {
      if (data) {
        login(data.accessToken, data.refreshToken, data.user);
        setSuccessMessage("Login successful!");
        setError("");
        navigate("/");
      } else {
        setError("Invalid OTP. Please try again.");
        setSuccessMessage("");
      }
    },
    onError: () => {
      setError("Failed to verify OTP. Please try again.");
      setSuccessMessage("");
    },
  });

  const handleSendOtp = () => {
    if (mobileNumber.length === 10) {
      sendOtpMutation.mutate(mobileNumber);
    } else {
      setError("Please enter a valid 10-digit mobile number.");
    }
  };

  const handleVerifyOtp = () => {
    if (otp.length === 6) {
      verifyOtpMutation.mutate({ mobileNumber, otp });
    } else {
      setError("Please enter a valid 6-digit OTP.");
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>

        <Box component="form" sx={{ mt: 3, width: "100%" }} noValidate>
          <TextField
            fullWidth
            required
            id="mobileNumber"
            label="Mobile Number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            margin="normal"
            variant="outlined"
            inputProps={{ maxLength: 10 }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSendOtp}
            disabled={sendOtpMutation.isLoading || isOtpSent}
            sx={{ mt: 2 }}
          >
            {sendOtpMutation.isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Send OTP"
            )}
          </Button>

          {isOtpSent && (
            <>
              <TextField
                fullWidth
                required
                id="otp"
                label="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: 6 }}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleVerifyOtp}
                disabled={verifyOtpMutation.isLoading}
                sx={{ mt: 2 }}
              >
                {verifyOtpMutation.isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Verify OTP"
                )}
              </Button>
            </>
          )}

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {successMessage && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {successMessage}
            </Alert>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
