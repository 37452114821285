import {
  Box,
  Grid,
  Typography,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const CustomerFullDisplay = ({ selectedCustomer }) => {
  return (
    <Box
      sx={{
        mt: 3,
        p: 3,
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Typography variant="h5" sx={{ color: "#1976d2", mb: 2 }}>
        Customer Details
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Name:</strong> {selectedCustomer.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Contact:</strong> {selectedCustomer.contactNumber}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Email:</strong> {selectedCustomer.email}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>City:</strong> {selectedCustomer.city}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Postal Code:</strong> {selectedCustomer.postalCode}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <strong>Address:</strong> {selectedCustomer.address}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <strong>Lead Source:</strong> {selectedCustomer.leadSource}
          </Typography>
        </Grid>
        {selectedCustomer.notes && (
          <Grid item xs={12}>
            <Typography variant="body1">
              <strong>Notes:</strong> {selectedCustomer.notes}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CustomerFullDisplay;
