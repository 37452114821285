import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { fetchAllRoles, createRole, updateRole } from "../../services/rolesApi";
import SettingsAddEditForm from "../../components/forms/SettingsAddEditForm";
import LoadingSpinner from "../../ui/LoadingSpinner";

export default function RoleManagement() {
  const queryClient = useQueryClient();
  const [formValues, setFormValues] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formMode, setFormMode] = useState("Add");

  // Fetch roles data
  const { data: roles = [], isLoading: isLoadingRoles } = useQuery(
    "roles",
    fetchAllRoles,
    {
      staleTime: Infinity, // Cache will never be considered stale
      cacheTime: Infinity, // Keep the data in memory indefinitely
      refetchOnWindowFocus: false, // Prevent refetching when the window regains focus
      refetchOnMount: false, // Prevent refetching when the component remounts
      refetchOnReconnect: false,
    }
  );

  const roleMutation = useMutation(
    (data) =>
      formMode === "Add" ? createRole(data) : updateRole(data.id, data),
    {
      onSuccess: () => queryClient.invalidateQueries("roles"),
    }
  );

  const handleRowEdit = (row) => {
    setFormMode("Edit");
    setFormValues(row);
    setValidationErrors({});
    setIsFormOpen(true);
  };

  const handleAddClick = () => {
    setFormMode("Add");
    setFormValues({ roleName: "" }); // Initial value for Add mode
    setValidationErrors({});
    setIsFormOpen(true);
  };

  const handleFormSubmit = () => {
    const errors = {};

    // Validation for Role Management
    if (!formValues.roleName?.trim()) {
      errors.roleName = "Role Name is required.";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    roleMutation.mutate(formValues);
    setIsFormOpen(false);
  };

  return (
    <Box p={3}>
      <Box mb={2}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleAddClick}
        >
          Add Role
        </Button>
      </Box>

      {isLoadingRoles ? (
        <Typography>
          <LoadingSpinner />
        </Typography>
      ) : (
        <DataGrid
          rows={roles}
          columns={[
            { field: "roleName", headerName: "Role Name", flex: 1 },
            {
              field: "actions",
              headerName: "Actions",
              width: 150,
              flex: 1,
              renderCell: (params) => (
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  onClick={() => handleRowEdit(params.row)}
                >
                  Edit
                </Button>
              ),
            },
          ]}
          pageSize={5}
          rowsPerPageOptions={[5, 10]}
          disableSelectionOnClick
          autoHeight
          getRowId={(row) => row.id}
          getRowHeight={() => "auto"}
        />
      )}

      <SettingsAddEditForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        onSubmit={handleFormSubmit}
        formValues={formValues}
        onFormChange={(e) =>
          setFormValues({ ...formValues, [e.target.name]: e.target.value })
        }
        mode={formMode}
        columns={[
          { field: "roleName", headerName: "Role Name", editable: true },
        ]}
        validationErrors={validationErrors}
      />
    </Box>
  );
}
