import React, { useState, version } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { fetchQuotationsByProject } from "../../services/quotationApi";
import LoadingSpinner from "../../ui/LoadingSpinner";
import ApprovedQuotationForm from "./ApprovedQuotationform";
import { id } from "date-fns/locale";

const ApprovedQuotations = ({ projectId }) => {
  const [selectedQuotationId, setSelectedQuotationId] = useState(null);
  const [openQuotationDialog, setOpenQuotationDialog] = useState(false);

  const {
    data: quotations,
    isLoading,
    error,
  } = useQuery(
    ["fetchQuotationsByProject", projectId],
    () => fetchQuotationsByProject(projectId),
    {
      refetchOnWindowFocus: false,
      retry: false, // Disable retries
      onError: (error) => {
        console.error("Error fetching quotations:", error.message);
      },
    }
  );

  // Open the dialog to view quotation details
  const handleViewQuotation = (quotationId) => {
    setSelectedQuotationId(quotationId);
    setOpenQuotationDialog(true);
  };

  // Close the dialog
  const handleCloseQuotationDialog = () => {
    setOpenQuotationDialog(false);
    setSelectedQuotationId(null);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <Typography variant="body2" color="error">
          No quotations found for the selected project.
        </Typography>
      ) : quotations?.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          No approved quotations are available for this project.
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Version</strong>
                </TableCell>
                <TableCell>
                  <strong>Total Area (sq ft)</strong>
                </TableCell>
                <TableCell>
                  <strong>Total Amount</strong>
                </TableCell>
                <TableCell>
                  <strong>Room(s)</strong>
                </TableCell>
                <TableCell>
                  <strong>Description</strong>
                </TableCell>
                <TableCell>
                  <strong>Room Scope</strong>
                </TableCell>
                <TableCell>
                  <strong>Category</strong>
                </TableCell>
                <TableCell>
                  <strong>Rate (sq ft)</strong>
                </TableCell>
                <TableCell>
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quotations.map((quotation) => (
                <TableRow key={quotation.id}>
                  <TableCell>{quotation.version}</TableCell>
                  <TableCell>{quotation.totalAreaSqft}</TableCell>
                  <TableCell>{quotation.totalAmount}</TableCell>
                  <TableCell>
                    {quotation.quotationItems?.map((item, index) => (
                      <Typography key={index}>{item.roomType}</Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    {quotation.quotationItems?.map((item, index) => (
                      <Typography key={index}>{item.description}</Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    {quotation.quotationItems?.map((item, index) => (
                      <Typography key={index}>{item.roomScope}</Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    {quotation.quotationItems?.map((item, index) => (
                      <Typography key={index}>{item.category}</Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    {quotation.quotationItems?.map((item, index) => (
                      <Typography key={index}>{item.ratePerSqft}</Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleViewQuotation(quotation.id)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Dialog to view individual quotation details */}
      {selectedQuotationId && (
        <ApprovedQuotationForm
          quotationId={selectedQuotationId}
          open={openQuotationDialog}
          onClose={handleCloseQuotationDialog}
        />
      )}
    </Box>
  );
};

export default ApprovedQuotations;
