import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useNotification } from "../context/NotificationContext"; // Ensure path is correct

function NotificationSnackbar() {
  const { notification, closeNotification } = useNotification();

  return (
    <Snackbar
      open={notification?.open}
      autoHideDuration={5000}
      onClose={closeNotification}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert onClose={closeNotification} severity="info" sx={{ width: "100%" }}>
        {notification?.message}
      </Alert>
    </Snackbar>
  );
}

export default NotificationSnackbar;
