import React, { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/SideBar";
import logo from "../assets/images/thriant-logo.png";
import { useAuth } from "../context/AuthContext";
import { generateToken } from "../notification/firebase";
import { onMessageListener } from "../notification/notificationService";
import { useNotification } from "../context/NotificationContext";
import NotificationSnackbar from "../ui/NotificationSnackbar";

const drawerWidth = 260;

const Main = styled(Box)`
  flex-grow: 1;
  padding: 10px;
  background-color: #fff;
  min-height: 100vh;
  margin-left: ${drawerWidth}px;
  transition: margin 0.3s ease-in-out;
`;

const StyledAppBar = styled(AppBar)`
  z-index: 1400;
  background-color: #ffffff;
  color: #333;
  box-shadow: none;
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

const ProfileSection = styled(Box)`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

const NotificationCard = styled(Card)`
  margin: 8px 16px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const ClearButton = styled(Button)`
  display: block;
  margin: 8px auto;
  font-size: 12px;
  color: #1976d2;
  text-transform: none;
`;

function DashboardLayout() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, logout } = useAuth();
  const [tokenFound, setTokenFound] = useState(false);
  const {
    showNotification,
    notificationCount,
    getUnreadNotifications,
    markAllAsRead,
  } = useNotification();
  const listenerAdded = useRef(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    const initMessaging = async () => {
      if (user && !listenerAdded.current) {
        await generateToken(setTokenFound);
        onMessageListener((payload) => {
          const title = payload.notification?.title || payload.data?.title;
          const body = payload.notification?.body || payload.data?.body;
          showNotification(title, body);
        });

        listenerAdded.current = true; // Ensure this runs only once
      }
    };

    initMessaging();
  }, [showNotification]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    setDrawerOpen(true); // Open profile drawer
    setAnchorEl(null);
  };

  const handleNotificationsClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleClearNotifications = () => {
    markAllAsRead();
    handleNotificationsClose(); // Close the menu after clearing notifications
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false); // Close the profile drawer
  };

  // Get user details from localStorage
  const userDetails = JSON.parse(localStorage.getItem("user")) || {};

  return (
    <Box sx={{ display: "flex" }}>
      <StyledAppBar position="fixed">
        <StyledToolbar>
          <Box display="flex" alignItems="center">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img src={logo} alt="Logo" style={{ height: 40, margin: 20 }} />
          </Box>

          <Box display="flex" alignItems="center" gap={2} mr={3}>
            {/* Notifications Icon */}
            <IconButton
              color="inherit"
              onClick={handleNotificationsClick}
              aria-label="Show notifications"
            >
              <Badge badgeContent={notificationCount} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            {/* Notifications Menu */}
            <Menu
              anchorEl={notificationAnchorEl}
              open={Boolean(notificationAnchorEl)}
              onClose={handleNotificationsClose}
              PaperProps={{
                style: {
                  maxHeight: 300,
                  width: 360,
                },
              }}
            >
              {getUnreadNotifications().length > 0 ? (
                getUnreadNotifications().map((notification, index) => (
                  <NotificationCard key={index}>
                    <CardContent>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {notification.title}
                      </Typography>
                      <Typography variant="body2">
                        {notification.message}
                      </Typography>
                    </CardContent>
                  </NotificationCard>
                ))
              ) : (
                <MenuItem>
                  <Typography variant="body2" textAlign="center">
                    No new notifications
                  </Typography>
                </MenuItem>
              )}
              <ClearButton onClick={handleClearNotifications}>
                Clear Notifications
              </ClearButton>
            </Menu>

            <ProfileSection onClick={handleMenuOpen}>
              <Avatar alt={user?.firstName || "User"} />
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                {userDetails?.firstName || "User"}
              </Typography>
            </ProfileSection>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleProfileClick}>View Profile</MenuItem>
              <Divider />
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </Box>
        </StyledToolbar>
      </StyledAppBar>

      <Sidebar />

      <Main component="main">
        <Toolbar />
        <Outlet />
      </Main>

      {/* Drawer for User Profile */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: {
            marginTop: "64px", // Adjust to match AppBar height
            height: "calc(100% - 64px)", // Full height minus AppBar height
          },
        }}
      >
        <Box
          sx={{
            width: 300,
            p: 3,
            backgroundColor: "#f9f9f9",
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              p: 2,
              backgroundColor: "#fff",
              borderRadius: 2,
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Avatar
              sx={{
                width: 80,
                height: 80,
                mb: 2,
                backgroundColor: "#1976d2",
                fontSize: "2rem",
              }}
            >
              {user?.firstName?.[0] || "U"}
            </Avatar>

            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", textAlign: "center", color: "#333" }}
            >
              {`${user?.firstName || "First Name"} ${
                user?.lastName || "Last Name"
              }`}
            </Typography>

            <Typography
              variant="body2"
              sx={{ color: "#555", textAlign: "center" }}
            >
              {`${user?.company.companyName || "Company"}
              `}
            </Typography>

            <Typography
              variant="body2"
              sx={{ color: "#555", textAlign: "center" }}
            >
              {user?.company?.address || "address not available"}
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#1976d2" }}
            >
              Account Details
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="body2" sx={{ color: "#555" }}>
                First Name:
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                {user?.firstName || "-"}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="body2" sx={{ color: "#555" }}>
                Last Name:
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                {user?.lastName || "-"}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="body2" sx={{ color: "#555" }}>
                Email:
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                {user?.email || "-"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 1,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="body2" sx={{ color: "#555" }}>
                Role:
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", color: "#333" }}
              >
                {user?.roles || "-"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Drawer>

      {/* Snackbar Notification */}
      <NotificationSnackbar />
    </Box>
  );
}

export default DashboardLayout;
