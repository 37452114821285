import apiClient from "./apiClient";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

// Fetch measurements for a specific project
export const fetchMeasurement = async (projectId) => {
  const response = await apiClient.get(
    `${API_BASE_URL}/measurements/project/${projectId}`
  );
  return response.data.data;
};

// Create a new measurement
export const createMeasurement = async (measurementData) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/measurements`,
    measurementData
  );
  return response.data.data;
};

export const updateMeasurement = async ({ measurementId, data }) => {
  const response = await apiClient.put(
    `${API_BASE_URL}/measurements/${measurementId}`,
    data
  );
  return response.data.data;
};

export const deleteMeasurement = async (measurementId) => {
  const response = await apiClient.delete(
    `${API_BASE_URL}/measurements/${measurementId}`
  );
  return response.data.data;
};

export const fetchCoatingOptions = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/coating-options`);
  return response.data.data;
};

export const createCoatingOption = async (coatingOption) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/coating-options`,
    coatingOption
  );
  return response.data.data;
};

// Update an existing coating option
export const updateCoatingOption = async (id, updatedCoatingOption) => {
  const response = await apiClient.put(
    `${API_BASE_URL}/coating-options/${id}`,
    updatedCoatingOption
  );
  return response.data.data;
};

// Delete (soft delete) a coating option by ID
export const deleteCoatingOption = async (id) => {
  const response = await apiClient.delete(
    `${API_BASE_URL}/coating-options/${id}`
  );
  return response.data.data;
};

export const fetchCategoryPricing = async () => {
  const response = await apiClient.get(
    `${API_BASE_URL}/measurement-categories`
  );
  return response.data.data;
};

export const createMeasurementCategory = async (category) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/measurement-categories`,
    category
  );
  return response.data.data;
};

export const updateMeasurementCategory = async (id, updatedCategory) => {
  const response = await apiClient.put(
    `${API_BASE_URL}/measurement-categories/${id}`,
    updatedCategory
  );
  return response.data.data;
};

export const deleteMeasurementCategory = async (id) => {
  const response = await apiClient.delete(
    `${API_BASE_URL}/measurement-categories/${id}`
  );
  return response.data.data;
};

export const submitMeasurementForApproval = async (measurementId) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/measurements/${measurementId}/submit`
  );
  return response.data.data;
};

export const approveMeasurement = async ({ measurementId, comments }) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/measurements/${measurementId}/approve`,
    { comments }
  );
  return response.data;
};

export const requestMeasurementRevisions = async ({
  measurementId,
  comments,
}) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/measurements/${measurementId}/request-revisions`,
    { comments }
  );
  return response.data;
};

export const requestQuotation = async (measurementId) => {
  const response = await apiClient.get(
    `${API_BASE_URL}/measurements/${measurementId}/quotation`
  );
  return response.data.data;
};
