import axios from "axios";
import { refreshTokenFunction } from "./loginApi";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const handleLogout = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("fcmToken");
  localStorage.removeItem("user");
  window.location.replace("/login"); // Redirect to login
};

// Request Interceptor
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error?.config;

    if (error?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `Bearer ${token}`;
            return apiClient(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem("refreshToken");
        if (!refreshToken) {
          console.warn("No refresh token available. Redirecting to login.");
          handleLogout();
          return Promise.reject(error);
        }

        const newTokens = await refreshTokenFunction(refreshToken);

        if (newTokens?.data?.accessToken && newTokens?.data?.refreshToken) {
          localStorage.setItem("accessToken", newTokens?.data?.accessToken);
          localStorage.setItem("refreshToken", newTokens?.data?.refreshToken);

          processQueue(null, newTokens.accessToken);

          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${newTokens.accessToken}`;
          return apiClient(originalRequest);
        } else {
          console.error("Failed to refresh tokens. Redirecting to login.");
          handleLogout();
          return Promise.reject(error);
        }
      } catch (refreshError) {
        console.error(
          "Refresh token failed:",
          refreshError.response || refreshError
        );
        if (refreshError.response?.status === 401) {
          console.error("Refresh token expired or invalid. Logging out.");
          handleLogout();
        }

        processQueue(refreshError, null);
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
