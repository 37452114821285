// CustomerForm.jsx

import { Box, TextField, MenuItem } from "@mui/material";
import { useState } from "react";

const leadSources = [
  { label: "Referral", value: "REFERRAL" },
  { label: "Social Media", value: "SOCIAL_MEDIA" },
  { label: "Website", value: "WEBSITE" },
  { label: "Event", value: "ADVERTISEMENT" },
  { label: "Other", value: "OTHER" },
];

const CustomerForm = ({ customerInfo, setCustomerInfo, errors }) => {
  const [isOtherSource, setIsOtherSource] = useState(
    customerInfo?.leadSource === "OTHER"
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleLeadSourceChange = (e) => {
    const value = e.target.value;
    setCustomerInfo((prev) => ({ ...prev, leadSource: value }));
    setIsOtherSource(value === "OTHER");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, padding: 3 }}>
      <TextField
        label="Name"
        name="name"
        value={customerInfo?.name || ""}
        onChange={handleInputChange}
        error={!!errors.name}
        helperText={errors.name}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Contact"
        name="contactNumber"
        value={customerInfo?.contactNumber || ""}
        onChange={handleInputChange}
        error={!!errors.contactNumber}
        helperText={errors.contactNumber}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Email"
        name="email"
        value={customerInfo?.email || ""}
        onChange={handleInputChange}
        error={!!errors.email}
        helperText={errors.email}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Address"
        name="address"
        value={customerInfo?.address || ""}
        onChange={handleInputChange}
        error={!!errors.address}
        helperText={errors.address}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="City"
        name="city"
        value={customerInfo?.city || ""}
        onChange={handleInputChange}
        error={!!errors.city}
        helperText={errors.city}
        fullWidth
        variant="outlined"
      />
      <TextField
        label="Postal Code"
        name="postalCode"
        value={customerInfo?.postalCode || ""}
        onChange={handleInputChange}
        error={!!errors.postalCode}
        helperText={errors.postalCode}
        fullWidth
        variant="outlined"
      />
      <TextField
        select
        label="Lead Source"
        name="leadSource"
        value={customerInfo?.leadSource || ""}
        onChange={handleLeadSourceChange}
        error={!!errors.leadSource}
        helperText={errors.leadSource}
        fullWidth
        variant="outlined"
      >
        {leadSources.map((source) => (
          <MenuItem key={source.value} value={source.value}>
            {source.label}
          </MenuItem>
        ))}
      </TextField>
      {isOtherSource && (
        <TextField
          label="Specify Lead Source"
          name="otherLeadSource"
          value={customerInfo?.otherLeadSource || ""}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
        />
      )}
      <TextField
        label="Notes"
        name="notes"
        value={customerInfo?.notes || ""}
        onChange={handleInputChange}
        fullWidth
        variant="outlined"
        multiline
        rows={3}
      />
    </Box>
  );
};

export default CustomerForm;
  