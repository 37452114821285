import { Box, Typography, Button, TextField, Modal } from "@mui/material";

const SettingsAddEditForm = ({
  isOpen,
  onClose,
  onSubmit,
  formValues,
  onFormChange,
  mode = "Add",
  columns,
  validationErrors,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        p={4}
        sx={{
          width: 400,
          margin: "100px auto",
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" gutterBottom>
          {mode === "Add" ? "Add New" : "Edit"} Item
        </Typography>
        {columns
          .filter((col) => col.editable || col.disableInEditMode) // Include only editable or disableInEditMode fields
          .map((col) => (
            <Box key={col.field} mb={2}>
              <TextField
                label={col.headerName}
                name={col.field}
                fullWidth
                margin="normal"
                value={formValues[col.field] || ""}
                onChange={(e) => onFormChange(e)}
                error={!!validationErrors[col.field]}
                helperText={validationErrors[col.field]}
                disabled={
                  mode === "Edit" && col.disableInEditMode // Dynamically disable fields in edit mode
                }
              />
            </Box>
          ))}
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="outlined" size="small" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={onSubmit}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SettingsAddEditForm;
