import { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null); // Store user info
  const navigate = useNavigate();

  // Set a fixed expiration duration (e.g., 15 minutes)
  // const EXPIRATION_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds
  const EXPIRATION_DURATION = 1 * 60 * 1000; // 15 minutes in milliseconds

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const userInfo = localStorage.getItem("user");
    const expirationTime = localStorage.getItem("expirationTime");

    if (accessToken && expirationTime && userInfo) {
      const currentTime = new Date().getTime();
      if (currentTime < expirationTime) {
        setIsAuthenticated(true);
        setUser(JSON.parse(userInfo)); // Restore user data from localStorage
        navigate("/");
      } else {
        logout(); // If the token is expired, log out the user
      }
    }
  }, []);

  const login = (accessToken, refreshToken, userInfo) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("user", JSON.stringify(userInfo)); // Store user data

    // Set expiration time
    const expirationTime = new Date().getTime() + EXPIRATION_DURATION;
    localStorage.setItem("expirationTime", expirationTime);

    setIsAuthenticated(true);
    setUser(userInfo); // Set user data in state
    navigate("/"); // Redirect to dashboard
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("fcmToken");
    localStorage.removeItem("user"); // Clear user data
    setIsAuthenticated(false);
    setUser(null); // Clear user state
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
