// src/components/BackButton.jsx
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <IconButton onClick={handleBack} color="primary" aria-label="back">
      <ArrowBackIcon />
    </IconButton>
  );
};

export default BackButton;
