import { useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Chip,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import {
  fetchAttendanceByProjectDateRange,
  fetchDailyAttendanceByDate,
  fetchAssignedLabours,
  fetchAttendanceByLabour,
  updatePaidStatus,
  updateAttendancePaidStatus,
  unAssignLabour,
} from "../services/laboursApi";
import LoadingSpinner from "../ui/LoadingSpinner";
import AssignLabourForm from "./forms/AssignLabourForm";
import { id } from "date-fns/locale";

const LaboursInfo = ({ projectId }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dailyDate, setDailyDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewLaboursModal, setViewLaboursModal] = useState(false);
  const [confirmPaidStatus, setConfirmPaidStatus] = useState(null); // Record for updating paid status
  const [selectedLabour, setSelectedLabour] = useState(null); // Track selected labour for detailed attendance
  const [confirmContext, setConfirmContext] = useState(null);

  // Fetch attendance summary for a range
  const {
    data: summaryData,
    isLoading: summaryLoading,
    refetch,
  } = useQuery(
    ["attendanceSummary", projectId, startDate, endDate],
    () => fetchAttendanceByProjectDateRange(projectId, startDate, endDate),
    {
      enabled: false, // Disabled initially, call refetch explicitly
    }
  );

  // Fetch daily attendance
  const { data: dailyData, isLoading: dailyLoading } = useQuery(
    ["dailyAttendance", projectId, dailyDate],
    () =>
      fetchDailyAttendanceByDate(
        projectId,
        moment(dailyDate).format("YYYY-MM-DD")
      ),
    {
      enabled: Boolean(dailyDate),
      staleTime: Infinity,
    }
  );

  // Fetch assigned labours
  const {
    data: laboursData,
    isLoading: isLaboursLoading,
    refetch: refetchLabours,
  } = useQuery(
    ["fetchAssignedLabours", projectId],
    () => fetchAssignedLabours(projectId),
    {
      enabled: !!projectId,
      staleTime: Infinity,
    }
  );

  const { mutate: unAssignLabourMutation, isLoading: isUnassigning } =
    useMutation(({ labourId }) => unAssignLabour(labourId), {
      onSuccess: () => {
        refetchLabours(); // Refresh the assigned labours list
      },
      onError: (error) => {
        console.error("Failed to unassign labour:", error.message);
      },
    });

  const handleUnassignLabour = (labourId) => {
    console.log("labourId", labourId);
    if (window.confirm("Are you sure you want to unassign this labour?")) {
      unAssignLabourMutation({ labourId });
    }
  };

  const {
    data: labourAttendanceData,
    isLoading: labourAttendanceLoading,
    refetch: refetchLabourAttendance,
  } = useQuery(
    ["labourAttendance", projectId, selectedLabour?.labourId],
    () => fetchAttendanceByLabour(projectId, selectedLabour?.labourId),
    {
      enabled: !!selectedLabour?.labourId, // Fetch only if a labour is selected
    }
  );

  const { mutate: updateSummaryPaidStatus, isLoading: isUpdatingPaidStatus } =
    useMutation(updatePaidStatus, {
      onSuccess: () => {
        setConfirmPaidStatus(null);
        refetch(); // Refetch attendance summary data
        if (selectedLabour) {
          refetchLabourAttendance(); // Refetch labour attendance data if a labour is selected
        }
      },
    });

  const {
    mutate: updateIndividualPaidStatus,
    isLoading: isTogglingPaidStatus,
  } = useMutation(updateAttendancePaidStatus, {
    onSuccess: () => {
      setConfirmPaidStatus(null);
      refetchLabourAttendance(); // Refetch labour attendance data
      refetch(); // Refetch attendance summary data
    },
  });

  const handleIndividualTogglePaidStatus = (record) => {
    setConfirmPaidStatus(record);
    setConfirmContext("individual"); // Mark the context as "individual"
  };

  // Handle attendance summary toggle
  const handlePaidStatusToggle = (record) => {
    setConfirmPaidStatus(record);
    setConfirmContext("summary"); // Mark the context as "summary"
  };

  const confirmPaidStatusUpdate = () => {
    if (confirmPaidStatus) {
      if (confirmContext === "summary") {
        const request = {
          projectId,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          paidStatus: !confirmPaidStatus.paidStatus,
          labourId: confirmPaidStatus.labourId,
        };
        updateSummaryPaidStatus(request);
      } else if (confirmContext === "individual") {
        updateIndividualPaidStatus({
          attendanceId: confirmPaidStatus.id,
          paidStatus: !confirmPaidStatus.paidStatus,
        });
      }
    }
  };

  const handleFilterClick = () => {
    if (startDate && endDate) {
      refetch();
    }
  };

  const handleLabourClick = (labour) => {
    if (labour?.labourId) {
      setSelectedLabour(labour);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    refetchLabours(); // Ensure the assigned labours are refreshed after the modal closes
  };

  // Daily attendance columns
  const dailyColumns = [
    { field: "labourName", headerName: "Labour Name", width: 150, flex: 1 },
    { field: "checkinTime", headerName: "Check-in Time", width: 150, flex: 1 },
    {
      field: "checkoutTime",
      headerName: "Check-out Time",
      width: 150,
      flex: 1,
    },
    {
      field: "checkinAddress",
      headerName: "Check-in Address",
      width: 200,
      flex: 1,
    },
    {
      field: "checkoutAddress",
      headerName: "Check-out Address",
      width: 200,
      flex: 1,
    },
    {
      field: "totalHoursWorked",
      headerName: "Hours Worked",
      width: 150,
      flex: 1,
    },
    {
      field: "attendanceStatus",
      headerName: "Attendance Status",
      width: 150,
      renderCell: (params) => (
        <span style={{ color: params.value === "PRESENT" ? "green" : "red" }}>
          {params.value}
        </span>
      ),
    },
  ];

  // Attendance summary columns
  const summaryColumns = [
    { field: "labourName", headerName: "Labour Name", width: 200, flex: 1 },
    { field: "totalHours", headerName: "Total Hours", width: 160, flex: 1 },
    { field: "totalDays", headerName: "Total Days", width: 150, flex: 1 },
    {
      field: "totalWages",
      headerName: "Total Wages",
      width: 150,
      flex: 1,
      valueFormatter: (params) => {
        `₹${params?.value}`;
      },
    },
    {
      field: "paidStatus",
      headerName: "Paid Status",
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Paid" : "Unpaid"}
          onClick={() => handlePaidStatusToggle(params.row)}
          style={{
            backgroundColor: params.value ? "green" : "orange",
            color: "white",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        />
      ),
    },
  ];

  return (
    <div>
      {/* Assign and View Labours Buttons */}
      <Box display="flex" justifyContent="space-between" mb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsModalOpen(true)}
        >
          Assign Labour
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setViewLaboursModal(true)}
        >
          View Labours
        </Button>
      </Box>

      {/* Daily Attendance Table */}
      <Typography variant="h5" sx={{ fontWeight: "bold", color: "#1976d2" }}>
        Daily Attendance
      </Typography>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Select Date"
            value={dailyDate}
            onChange={(newValue) => setDailyDate(newValue)}
          />
        </LocalizationProvider>
      </Box>
      <Box style={{ width: "100%", marginBottom: "30px" }}>
        {dailyLoading ? (
          <LoadingSpinner />
        ) : (
          <DataGrid
            rows={
              dailyData?.map((record) => ({
                id: record.id,
                labourId: record.projectLabour.labour.id,
                labourName: record.projectLabour.labour.userName,
                checkinTime: record.checkinTime || "-",
                checkoutTime: record.checkoutTime || "-",
                checkinAddress: record.checkinAddress || "-",
                checkoutAddress: record.checkoutAddress || "-",
                totalHoursWorked: record.totalHoursWorked || "-",
                attendanceStatus: record.attendanceStatus,
              })) || []
            }
            columns={dailyColumns}
            pageSize={5}
            disableSelectionOnClick
            getRowHeight={() => "auto"}
          />
        )}
      </Box>

      {/* Attendance Summary Table */}
      <Typography variant="h5" sx={{ fontWeight: "bold", color: "#1976d2" }}>
        Attendance Summary
      </Typography>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
          />
          <DatePicker label="End Date" value={endDate} onChange={setEndDate} />
        </LocalizationProvider>
        <Button
          variant="contained"
          onClick={handleFilterClick}
          disabled={!startDate || !endDate}
        >
          Filter Attendance
        </Button>
      </Box>
      <Box style={{ width: "100%", marginBottom: "30px" }}>
        {summaryLoading ? (
          <LoadingSpinner />
        ) : (
          <DataGrid
            rows={
              summaryData?.data.map((record, index) => ({
                id: index,
                labourId: record.labourId,
                labourName: record.labourName,
                totalHours: record.totalHours || "",
                totalDays: record.totalDays || "",
                totalWages: record.totalWages || "",
                paidStatus: record.paidStatus,
              })) || []
            }
            columns={summaryColumns}
            pageSize={5}
            disableSelectionOnClick
            getRowHeight={() => "auto"}
          />
        )}
      </Box>

      {/* Detailed Attendance Table */}
      {selectedLabour && (
        <Box>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", marginTop: "20px", color: "#1976d2" }}
          >
            Detailed Attendance for {selectedLabour.labourName}
          </Typography>
          <Box style={{ width: "100%", marginBottom: "30px" }}>
            {labourAttendanceLoading ? (
              <LoadingSpinner />
            ) : Array.isArray(labourAttendanceData?.data) ? ( // Ensure labourAttendanceData is an array
              <DataGrid
                rows={labourAttendanceData.data.map((record) => ({
                  id: record.id,
                  labourId: record.projectLabour?.labour.id,
                  attendanceDate: record.attendanceDate,
                  checkinTime: record.checkinTime || "-",
                  checkoutTime: record.checkoutTime || "-",
                  totalHoursWorked: record.totalHoursWorked || "-",
                  dailyWageCalculated: record.dailyWageCalculated || "-",
                  paidStatus: record.paidStatus,
                }))}
                columns={[
                  { field: "attendanceDate", headerName: "Date", width: 150 },
                  {
                    field: "checkinTime",
                    headerName: "Check-in Time",
                    width: 150,
                    flex: 1,
                  },
                  {
                    field: "checkoutTime",
                    headerName: "Check-out Time",
                    width: 150,
                    flex: 1,
                  },
                  {
                    field: "totalHoursWorked",
                    headerName: "Hours Worked",
                    width: 150,
                    flex: 1,
                  },
                  {
                    field: "dailyWageCalculated",
                    headerName: "Daily Wage",
                    width: 150,
                    flex: 1,
                  },
                  {
                    field: "paidStatus",
                    headerName: "Paid Status",
                    width: 150,
                    renderCell: (params) => {
                      let statusLabel = "Unpaid";
                      let chipColor = "orange";

                      if (params.value === true) {
                        statusLabel = "Paid";
                        chipColor = "green";
                      } else if (
                        params.row.paidStatusCount > 0 &&
                        params.row.unpaidStatusCount > 0
                      ) {
                        statusLabel = "Partially Paid";
                        chipColor = "blue";
                      }

                      return (
                        <Chip
                          label={statusLabel}
                          onClick={() =>
                            handleIndividualTogglePaidStatus(params.row)
                          }
                          style={{
                            backgroundColor: chipColor,
                            color: "white",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        />
                      );
                    },
                  },
                ]}
                pageSize={5}
                disableSelectionOnClick
                getRowHeight={() => "auto"}
              />
            ) : (
              <Typography variant="body2" color="textSecondary">
                No detailed attendance data available for this labour.
              </Typography>
            )}
          </Box>
        </Box>
      )}

      {/* Modals for Assign and View Labours */}
      {/* Assign Labour Modal */}
      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Assign Labour</DialogTitle>
        <DialogContent>
          <AssignLabourForm
            open={isModalOpen}
            onClose={handleModalClose}
            projectId={projectId}
          />
        </DialogContent>
      </Dialog>
      {/* View Labours Modal */}
      <Dialog
        open={viewLaboursModal}
        onClose={() => setViewLaboursModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Assigned Labours</DialogTitle>
        <DialogContent>
          {isLaboursLoading ? (
            <LoadingSpinner />
          ) : (
            <DataGrid
              rows={
                laboursData?.labours.map((labour) => ({
                  id: labour?.id,
                  name: labour?.labour?.userName,
                  contactNumber: labour?.labour?.contactNumber,
                  assignmentDate: labour?.assignmentDate,
                })) || []
              }
              columns={[
                {
                  field: "name",
                  headerName: "Labour Name",
                  width: 200,
                  flex: 1,
                },
                {
                  field: "contactNumber",
                  headerName: "Contact",
                  width: 200,
                  flex: 1,
                },
                {
                  field: "assignmentDate",
                  headerName: "Assignment Date",
                  width: 200,
                  flex: 1,
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  width: 150,
                  renderCell: (params) => (
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => handleUnassignLabour(params.row?.id)}
                      disabled={isUnassigning}
                    >
                      Unassign
                    </Button>
                  ),
                },
              ]}
              pageSize={5}
              disableSelectionOnClick
              getRowHeight={() => "auto"}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Paid Status Confirmation Modal */}
      {confirmPaidStatus && (
        <Dialog
          open={Boolean(confirmPaidStatus)}
          onClose={() => setConfirmPaidStatus(null)}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle>Confirm Paid Status Update</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to mark{" "}
              <strong>{confirmPaidStatus.labourName}</strong> as{" "}
              {confirmPaidStatus.paidStatus ? "Unpaid" : "Paid"}?
            </Typography>
            <Box mt={3} display="flex" justifyContent="flex-end">
              <Button
                onClick={() => setConfirmPaidStatus(null)}
                variant="outlined"
                sx={{ mr: 2 }}
              >
                Cancel
              </Button>
              <Button
                onClick={confirmPaidStatusUpdate}
                variant="contained"
                color="primary"
                disabled={isUpdatingPaidStatus}
              >
                Confirm
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default LaboursInfo;
