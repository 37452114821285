// HistoryButton.js
import { IconButton } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";

const HistoryButton = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        color: "#1976d2",
        backgroundColor: "#f0f0f0",
        "&:hover": { backgroundColor: "#e0e0e0" },
        borderRadius: "8px",
      }}
    >
      <HistoryIcon />
    </IconButton>
  );
};

export default HistoryButton;
