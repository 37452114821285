import apiClient from "./apiClient";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const fetchUsersWithRoles = async () => {
  const response = await apiClient.get(
    `${API_BASE_URL}/users/users-with-roles`
  );
  return response.data.data;
};

export const addUser = async (userData) => {
  const response = await apiClient.post(`${API_BASE_URL}/users`, userData);
  return response.data;
};

export const updateUser = async ({ id, updatedData }) => {
  const response = await apiClient.put(
    `${API_BASE_URL}/users/${id}`,
    updatedData
  );
  return response.data;
};

export const deleteUser = async (userId) => {
  const response = await apiClient.delete(`${API_BASE_URL}/users/${userId}`);
  return response.data;
};
