// src/pages/tasks-management/TasksPage.jsx
import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

// Sample project and tasks data
const projectsData = [
  { id: 1, name: "Villa Painting Project" },
  { id: 2, name: "Office Renovation Project" },
  // Add more projects as needed
];

const tasksData = [
  {
    id: 1,
    projectId: 1,
    name: "Wall Measurement",
    workflowStage: "Measurements",
    status: "In Progress",
    labor: ["John Doe", "Anna Smith"],
  },
  {
    id: 2,
    projectId: 1,
    name: "Ceiling Measurement",
    workflowStage: "Measurements",
    status: "Pending",
    labor: ["Michael Brown"],
  },
  {
    id: 3,
    projectId: 1,
    name: "Prepare Quotation",
    workflowStage: "Quotations",
    status: "Pending",
    labor: ["Anna Smith"],
  },
  {
    id: 4,
    projectId: 2,
    name: "Furniture Arrangement",
    workflowStage: "Labour Assignment",
    status: "Completed",
    labor: ["Alice", "Bob"],
  },
  // Add more tasks as needed
];

const workflowStages = [
  "All Stages",
  "Measurements",
  "Quotations",
  "Labour Assignment",
];

const TasksPage = () => {
  const [searchParams] = useSearchParams();
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [selectedWorkflowStage, setSelectedWorkflowStage] =
    useState("All Stages");
  const [filteredTasks, setFilteredTasks] = useState([]);

  // Get the project ID from URL query params
  useEffect(() => {
    const projectIdFromURL = searchParams.get("projectId");
    if (projectIdFromURL) {
      setSelectedProjectId(projectIdFromURL);
    }
  }, [searchParams]);

  // Handle project selection change
  const handleProjectChange = (event) => {
    setSelectedProjectId(event.target.value);
  };

  // Handle workflow stage selection change
  const handleWorkflowStageChange = (event) => {
    setSelectedWorkflowStage(event.target.value);
  };

  // Filter tasks by the selected project and workflow stage
  useEffect(() => {
    let tasks = tasksData;
    if (selectedProjectId) {
      tasks = tasks.filter(
        (task) => task.projectId === Number(selectedProjectId)
      );
    }
    if (selectedWorkflowStage !== "All Stages") {
      tasks = tasks.filter(
        (task) => task.workflowStage === selectedWorkflowStage
      );
    }
    setFilteredTasks(tasks);
  }, [selectedProjectId, selectedWorkflowStage]);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        All Tasks
      </Typography>

      {/* Project Selection Dropdown */}
      <FormControl sx={{ minWidth: 200, marginBottom: 3 }}>
        <InputLabel>Filter by Project</InputLabel>
        <Select
          value={selectedProjectId}
          onChange={handleProjectChange}
          label="Filter by Project"
        >
          <MenuItem value="">
            <em>All Projects</em>
          </MenuItem>
          {projectsData.map((project) => (
            <MenuItem key={project.id} value={project.id}>
              {project.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Workflow Stage Selection Dropdown */}
      <FormControl sx={{ minWidth: 200, marginBottom: 3 }}>
        <InputLabel>Filter by Workflow Stage</InputLabel>
        <Select
          value={selectedWorkflowStage}
          onChange={handleWorkflowStageChange}
          label="Filter by Workflow Stage"
        >
          {workflowStages.map((stage) => (
            <MenuItem key={stage} value={stage}>
              {stage}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Task List Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Project Name</strong>
              </TableCell>
              <TableCell>
                <strong>Task Name</strong>
              </TableCell>
              <TableCell>
                <strong>Workflow Stage</strong>
              </TableCell>
              <TableCell>
                <strong>Assigned To</strong>
              </TableCell>
              <TableCell>
                <strong>Status</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTasks.map((task) => (
              <TableRow key={task.id}>
                <TableCell>
                  {projectsData.find((p) => p.id === task.projectId)?.name}
                </TableCell>
                <TableCell>{task.name}</TableCell>
                <TableCell>{task.workflowStage}</TableCell>
                <TableCell>{task.labor.join(", ")}</TableCell>
                <TableCell>{task.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TasksPage;
