import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Link,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Edit, Delete, Add } from "@mui/icons-material";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  addUser,
  updateUser,
  fetchUsersWithRoles,
  deleteUser,
} from "../../services/usersApi";
import { fetchAllRoles } from "../../services/rolesApi";
import UserForm from "../../components/forms/UserForm";
import LoadingSpinner from "../../ui/LoadingSpinner";

export default function UserManagement() {
  const queryClient = useQueryClient();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isRoleDialogOpen, setIsRoleDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formMode, setFormMode] = useState("Add");

  // Fetch users
  const { data: users = [], isLoading: isLoadingUsers } = useQuery(
    "users",
    fetchUsersWithRoles,
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  const { data: roles = [], isLoading: isLoadingRoles } = useQuery(
    "roles",
    fetchAllRoles,
    {
      staleTime: Infinity, // Cache will never be considered stale
      cacheTime: Infinity, // Keep the data in memory indefinitely
      refetchOnWindowFocus: false, // Prevent refetching when the window regains focus
      refetchOnMount: false, // Prevent refetching when the component remounts
      refetchOnReconnect: false,
    }
  );

  // Mutations
  const addMutation = useMutation(addUser, {
    onSuccess: () => queryClient.invalidateQueries("users"),
  });

  const updateMutation = useMutation(updateUser, {
    onSuccess: () => queryClient.invalidateQueries("users"),
  });

  const deleteMutation = useMutation(deleteUser, {
    onSuccess: () => queryClient.invalidateQueries("users"),
  });

  // Handlers
  const handleAddClick = () => {
    setFormMode("Add");
    setSelectedUser(null);
    setIsFormOpen(true);
  };

  const handleEditClick = (user) => {
    setFormMode("Edit");
    setSelectedUser(user);
    setIsFormOpen(true);
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    deleteMutation.mutate(selectedUser.userId, {
      onSuccess: () => setIsDeleteDialogOpen(false),
    });
  };

  const handleFormSubmit = (formValues) => {
    if (formMode === "Add") {
      addMutation.mutate(formValues);
    } else {
      updateMutation.mutate({
        id: selectedUser.userId,
        updatedData: formValues,
      });
    }
    setIsFormOpen(false);
  };

  const handleAddRoleClick = () => {
    setIsRoleDialogOpen(true);
  };

  const closeAddRoleDialog = () => {
    setIsRoleDialogOpen(false);
  };

  const columns = [
    { field: "firstName", headerName: "First Name", flex: 1 },
    { field: "lastName", headerName: "Last Name", flex: 1 },
    { field: "mobileNumber", headerName: "Mobile Number", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "roles",
      headerName: "Roles",
      flex: 2,
      renderCell: (params) => (
        <Box
          sx={{ display: "flex", gap: 0.5, flexWrap: "wrap", margin: "10px" }}
        >
          {params.row.roles.map((role, index) => (
            <Chip
              key={index}
              label={role}
              variant="outlined"
              size="small"
              sx={{ fontSize: "0.75rem", height: "24px" }}
            />
          ))}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton
            color="primary"
            onClick={() => handleEditClick(params.row)}
          >
            <Edit />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => handleDeleteClick(params.row)}
          >
            <Delete />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box p={3}>
      <Box
        mb={2}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
      >
        <Button
          size="small"
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleAddClick}
        >
          Add User
        </Button>
      </Box>

      {isLoadingUsers || isLoadingRoles ? (
        <Typography>
          <LoadingSpinner />
        </Typography>
      ) : (
        <DataGrid
          rows={users}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10]}
          disableSelectionOnClick
          autoHeight
          getRowHeight={() => "auto"}
          getRowId={(row) => row.userId}
        />
      )}

      <UserForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        onSubmit={handleFormSubmit}
        initialValues={selectedUser}
        mode={formMode}
        roles={roles}
      />

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the user{" "}
          <strong>
            {selectedUser?.firstName} {selectedUser?.lastName}
          </strong>
          ?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsDeleteDialogOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isRoleDialogOpen} onClose={closeAddRoleDialog}>
        <DialogTitle>Add New Role</DialogTitle>
        <DialogContent>
          <Typography>Add role form goes here (to be implemented).</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAddRoleDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={() => {}} color="primary" variant="contained">
            Save Role
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
