import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { AddCircleOutline, Edit, Delete } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  fetchLabours,
  createLabour,
  updateLabour,
  deleteLabour,
  getLabourDetails,
} from "../../services/laboursApi";
import { fetchUsersWithRoles } from "../../services/usersApi";
import LoadingSpinner from "../../ui/LoadingSpinner";
import LabourForm from "../../components/forms/LabourForm";

const LabourSheetPage = () => {
  const queryClient = useQueryClient();

  // Fetch labours and users with roles
  const {
    data: labours = [],
    isLoading: isLoadingLabours,
    error: labourError,
  } = useQuery("labours", fetchLabours);
  const {
    data: users = [],
    isLoading: isLoadingUsers,
    error: usersError,
  } = useQuery("usersWithRoles", fetchUsersWithRoles);

  useEffect(() => {
    console.log("usersss", users);
  }, [users]);

  // State management for dialogs
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedLabour, setSelectedLabour] = useState(null);
  const [labourToDelete, setLabourToDelete] = useState(null);
  const [labourDetails, setLabourDetails] = useState(null);

  // Mutations for CRUD operations
  const { mutate: addLabour } = useMutation(createLabour, {
    onSuccess: () => queryClient.invalidateQueries("labours"),
  });
  const { mutate: editLabour } = useMutation(updateLabour, {
    onSuccess: () => queryClient.invalidateQueries("labours"),
  });
  const { mutate: removeLabour } = useMutation(deleteLabour, {
    onSuccess: () => queryClient.invalidateQueries("labours"),
  });

  // Handlers for open/close dialogs
  const handleOpenDialog = (labour = null) => {
    setSelectedLabour(labour ? { ...labour } : {});
    setOpenDialog(true);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const handleOpenConfirmDialog = (labourId) => {
    setLabourToDelete(labourId);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);

  // CRUD handlers
  const handleDeleteLabour = () => {
    if (labourToDelete) {
      removeLabour(labourToDelete);
      setOpenConfirmDialog(false);
    }
  };

  const handleSubmit = (labourData) => {
    console.log("labourData", labourData);
    if (labourData.id) {
      editLabour(labourData);
    } else {
      addLabour(labourData);
    }
    setOpenDialog(false);
  };

  const handleRowClick = (params) => {
    const labourId = params.row.id;
    getLabourDetails(labourId).then((details) => setLabourDetails(details));
  };

  // Column definitions
  const columns = [
    { field: "userName", headerName: "Name", flex: 1 },
    { field: "contactNumber", headerName: "Contact Number", flex: 1 },
    { field: "skills", headerName: "Skill", flex: 1 },
    { field: "address", headerName: "Address", flex: 1 },
    { field: "experienceLevel", headerName: "Experience Level", flex: 1 },
    { field: "language", headerName: "Language", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton
            color="primary"
            onClick={() => handleOpenDialog(params.row)}
          >
            <Edit />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => handleOpenConfirmDialog(params.row.id)}
          >
            <Delete />
          </IconButton>
        </Box>
      ),
    },
  ];

  // Handle loading and error states
  if (isLoadingLabours || isLoadingUsers) return <LoadingSpinner />;
  if (labourError || usersError)
    return (
      <Typography>
        Error loading data: {labourError?.message || usersError?.message}
      </Typography>
    );

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#1976d2" }}>
          Labours
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutline />}
          size="small"
          onClick={() => handleOpenDialog()}
        >
          Add Labour
        </Button>
      </Box>

      <Box sx={{ overflowX: "auto" }}>
        <DataGrid
          rows={labours}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          sortingOrder={["asc", "desc"]}
          autoHeight
          onRowClick={handleRowClick}
          getRowHeight={() => "auto"}
        />
      </Box>

      {labourDetails && (
        <Box
          sx={{
            mt: 3,
            p: 3,
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
            boxShadow: "0 2px 12px rgba(0, 0, 0, 0.05)",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Labour Details
          </Typography>
          <Typography>Name: {labourDetails.userName}</Typography>
          <Typography>Contact: {labourDetails.contactNumber}</Typography>
          <Typography>Skills: {labourDetails.skills}</Typography>
          <Typography>Address: {labourDetails.address}</Typography>
          <Typography>
            Experience Level: {labourDetails.experienceLevel}
          </Typography>
          <Typography>Languages: {labourDetails.language}</Typography>
        </Box>
      )}

      {/* Form for Adding/Editing Labour */}
      <LabourForm
        open={openDialog}
        onClose={handleCloseDialog}
        onSubmit={handleSubmit}
        initialValues={selectedLabour}
        users={users}
      />

      {/* Confirm Delete Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        maxWidth="xs"
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this labour?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteLabour} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LabourSheetPage;
