import { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SettingsAddEditForm from "../../components/forms/SettingsAddEditForm";
import {
  fetchCategoryPricing,
  fetchCoatingOptions,
  createMeasurementCategory,
  updateMeasurementCategory,
  deleteMeasurementCategory,
  createCoatingOption,
  updateCoatingOption,
  deleteCoatingOption,
} from "../../services/measurementApi";
import { configurationMap } from "../../components/constants/configurationMap";

export default function ConfigurationSettings() {
  const [selectedConfig, setSelectedConfig] = useState("measurementCategory");
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formMode, setFormMode] = useState("Add");

  // Fetch data when configuration changes
  useEffect(() => {
    const fetchData = async () => {
      let data = [];
      if (selectedConfig === "measurementCategory") {
        data = await fetchCategoryPricing();
      } else if (selectedConfig === "coatingOptions") {
        data = await fetchCoatingOptions();
      }
      setRows(data);
      setColumns(configurationMap[selectedConfig].columns);
    };
    fetchData();
  }, [selectedConfig]);

  const handleAddClick = () => {
    setFormMode("Add");
    const initialValues = {};
    configurationMap[selectedConfig].columns.forEach((col) => {
      if (col.editable) initialValues[col.field] = "";
    });
    setFormValues(initialValues);
    setValidationErrors({});
    setIsFormOpen(true);
  };

  const handleEditClick = (row) => {
    setFormMode("Edit");
    setFormValues(row);
    setValidationErrors({});
    setIsFormOpen(true);
  };

  const handleDeleteClick = async (row) => {
    if (selectedConfig === "measurementCategory") {
      await deleteMeasurementCategory(row.id);
    } else if (selectedConfig === "coatingOptions") {
      await deleteCoatingOption(row.id);
    }
    const updatedRows = rows.filter((r) => r.id !== row.id);
    setRows(updatedRows);
  };

  const handleFormSubmit = async () => {
    if (formMode === "Add") {
      if (selectedConfig === "measurementCategory") {
        await createMeasurementCategory(formValues);
      } else if (selectedConfig === "coatingOptions") {
        await createCoatingOption(formValues);
      }
    } else if (formMode === "Edit") {
      if (selectedConfig === "measurementCategory") {
        await updateMeasurementCategory(formValues.id, formValues);
      } else if (selectedConfig === "coatingOptions") {
        await updateCoatingOption(formValues.id, formValues);
      }
    }
    setIsFormOpen(false);
    setRows(
      await (selectedConfig === "measurementCategory"
        ? fetchCategoryPricing()
        : fetchCoatingOptions())
    );
  };

  return (
    <Box>
      <Box mb={3}>
        <FormControl fullWidth variant="outlined" sx={{ maxWidth: 300 }}>
          <InputLabel id="config-select-label">Configuration</InputLabel>
          <Select
            labelId="config-select-label"
            value={selectedConfig}
            onChange={(e) => setSelectedConfig(e.target.value)}
            label="Configuration"
          >
            {Object.keys(configurationMap).map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box mb={2}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleAddClick}
        >
          Add
        </Button>
      </Box>

      <DataGrid
        rows={rows}
        columns={[
          ...columns,
          {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => (
              <Box display="flex" gap={1}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleEditClick(params.row)}
                >
                  Edit
                </Button>
              </Box>
            ),
          },
        ]}
        pageSize={10}
        rowsPerPageOptions={[5, 10]}
        disableSelectionOnClick
        getRowId={(row) => row.id}
        getRowHeight={() => "auto"}
      />

      <SettingsAddEditForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        onSubmit={handleFormSubmit}
        formValues={formValues}
        onFormChange={(e) =>
          setFormValues({ ...formValues, [e.target.name]: e.target.value })
        }
        mode={formMode}
        columns={columns}
        validationErrors={validationErrors}
      />
    </Box>
  );
}
