import apiClient from "./apiClient";
import axios from "axios";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const sendOtp = async (mobileNumber) => {
  console.log("base", BASE_URL);
  const response = await apiClient.post(`${BASE_URL}/auth/send-otp`, {
    mobileNumber,
  });
  return response.data;
};

export const verifyOtp = async ({ mobileNumber, otp }) => {
  const response = await apiClient.post(`${BASE_URL}/auth/verify-otp`, {
    mobileNumber,
    otp,
  });
  return response.data.data;
};

export const refreshTokenFunction = async (refreshToken) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/auth/refresh-token`,
      { refreshToken }, // No body if refresh token is in the header
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`, // Send refresh token in Authorization header
        },
      }
    );
    return response.data; // { accessToken, refreshToken }
  } catch (error) {
    console.error("Error refreshing token:", error.response || error);
    throw error; // This will be caught in the interceptor
  }
};
