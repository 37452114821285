import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { ExperienceLevels } from "../constants/constants";

const LabourForm = ({ open, onClose, onSubmit, initialValues, users }) => {
  const [formValues, setFormValues] = useState({
    id: "", // Include labourId
    userId: "",
    skills: "",
    address: "",
    experienceLevel: "",
    language: "",
    ratePerDay: "",
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (initialValues) {
      setFormValues({
        id: initialValues.id || "",
        userId: initialValues.userId || "",
        skills: initialValues.skills || "",
        address: initialValues.address || "",
        experienceLevel: initialValues.experienceLevel || "",
        language: initialValues.language || "",
        ratePerDay: initialValues.ratePerDay || "",
      });
    } else {
      setFormValues({
        id: "",
        userId: "",
        skills: "",
        address: "",
        experienceLevel: "",
        language: "",
        ratePerDay: "",
      });
    }
  }, [initialValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value || "" }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formValues.userId) newErrors.userId = "User is required";
    if (!formValues.skills) newErrors.skills = "Skills are required";
    if (!formValues.address) newErrors.address = "Address is required";
    if (!formValues.experienceLevel)
      newErrors.experienceLevel = "Experience level is required";
    if (!formValues.language) newErrors.language = "Language is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit(formValues);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{initialValues ? "Edit Labour" : "Add Labour"}</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          name="userId"
          value={formValues.userId || ""}
          onChange={handleChange}
          displayEmpty
          error={!!errors.userId}
          sx={{ mb: 2 }}
        >
          <MenuItem value="" disabled>
            Select User
          </MenuItem>
          {users &&
            users.map((user) => (
              <MenuItem key={user.userId} value={user.userId}>
                {user.firstName} {user.lastName} ({user.mobileNumber})
              </MenuItem>
            ))}
        </Select>

        {errors.userId && (
          <Typography color="error">{errors.userId}</Typography>
        )}

        <TextField
          fullWidth
          label="Skills"
          name="skills"
          value={formValues.skills || ""}
          onChange={handleChange}
          error={!!errors.skills}
          helperText={errors.skills}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Address"
          name="address"
          value={formValues.address || ""}
          onChange={handleChange}
          error={!!errors.address}
          helperText={errors.address}
          sx={{ mb: 2 }}
        />
        <Select
          fullWidth
          name="experienceLevel"
          value={formValues.experienceLevel || ""}
          onChange={handleChange}
          displayEmpty
          error={!!errors.experienceLevel}
          sx={{ mb: 2 }}
        >
          <MenuItem value="" disabled>
            Select Experience Level
          </MenuItem>
          {ExperienceLevels.map((level) => (
            <MenuItem key={level.value} value={level.value}>
              {level.label}
            </MenuItem>
          ))}
        </Select>
        {errors.experienceLevel && (
          <Typography color="error">{errors.experienceLevel}</Typography>
        )}
        <TextField
          fullWidth
          label="Language"
          name="language"
          value={formValues.language || ""}
          onChange={handleChange}
          error={!!errors.language}
          helperText={errors.language}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          label="Rate Per Day"
          name="ratePerDay"
          value={formValues.ratePerDay || ""}
          onChange={handleChange}
          type="number"
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LabourForm;
