import { Box, Button, TextField, Typography } from "@mui/material";
import { ApprovalStatus } from "../components/constants/constants";

const ApprovalSection = ({
  status,
  comments, // Pass the editable comments state from the parent
  savedComments,
  errorMessage,
  setComments,
  handleApprove,
  handleReject,
}) => {
  const isCommentsDisabled = status !== ApprovalStatus.PENDING_APPROVAL;

  return (
    <Box sx={{ mt: 2 }}>
      {status === ApprovalStatus.PENDING_APPROVAL ? (
        <>
          <TextField
            label="Comments"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={comments} // Use the editable state here
            onChange={(e) => setComments(e.target.value)} // Update the state when user types
            sx={{ mb: 2 }}
            disabled={isCommentsDisabled}
          />
          {errorMessage && (
            <Typography color="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Typography>
          )}
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleApprove}
              sx={{ textTransform: "none" }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleReject}
              sx={{ textTransform: "none" }}
            >
              Revise
            </Button>
          </Box>
        </>
      ) : (
        savedComments && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">
              <strong>Comments:</strong> {savedComments}
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
};

export default ApprovalSection;
