import {
  Box,
  IconButton,
  Grid,
  Card,
  CardMedia,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"; // Icon for non-image files
import { useState } from "react";

const AttachmentsSection = ({
  attachments,
  editable = false,
  onAddAttachment,
  onRemoveAttachment,
}) => {
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  // Helper function to determine if a file is an image
  const isImageFile = (file) => {
    const imageTypes = ["image/jpeg", "image/png", "image/gif"];
    return file instanceof File
      ? imageTypes.includes(file.type)
      : typeof file === "string" &&
          imageTypes.some((type) => file.endsWith(type));
  };

  // Get attachment URL for images
  const getAttachmentImage = (file) => {
    return file instanceof File || file instanceof Blob
      ? URL.createObjectURL(file)
      : typeof file === "string"
      ? file
      : "";
  };

  // Open dialog to view attachment
  const handleViewAttachment = (attachment) => {
    setSelectedAttachment(attachment);
    setOpenViewDialog(true);
  };

  // Close view dialog
  const handleCloseDialog = () => {
    setOpenViewDialog(false);
    setSelectedAttachment(null);
  };

  // Handle file upload
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      onAddAttachment(file);
    }
    event.target.value = null;
  };

  return (
    <Box>
      {/* Upload Attachment Button */}
      {editable && (
        <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            component="label"
            startIcon={<AddPhotoAlternateIcon />}
          >
            Upload Attachment
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
        </Box>
      )}

      {attachments && attachments.length > 0 ? (
        <Grid container spacing={2}>
          {attachments.map((file, index) => (
            <Grid item key={index}>
              <Card sx={{ position: "relative", width: 100, height: 100 }}>
                {isImageFile(file) ? (
                  <CardMedia
                    component="img"
                    height="100"
                    image={getAttachmentImage(file)}
                    alt={file.name || "Attachment"}
                    sx={{ objectFit: "cover", cursor: "pointer" }}
                    onClick={() => handleViewAttachment(file)}
                  />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => handleViewAttachment(file)}
                  >
                    <InsertDriveFileIcon sx={{ fontSize: 48, color: "gray" }} />
                  </Box>
                )}
                {editable && (
                  <IconButton
                    size="small"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                    }}
                    onClick={() => onRemoveAttachment(index)}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Card>
              <Typography variant="body2" align="center">
                {file.name || "Attachment"}
              </Typography>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body2" color="textSecondary">
          No attachments available.
        </Typography>
      )}

      {/* Dialog for Viewing Attachment */}
      <Dialog
        open={openViewDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            onClick={() => handleDownloadAttachment(selectedAttachment)}
            sx={{ color: "primary.main", mr: 1 }}
          >
            <DownloadIcon />
          </IconButton>
          <IconButton
            onClick={handleCloseDialog}
            sx={{ color: "primary.main" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedAttachment && isImageFile(selectedAttachment) ? (
            <img
              src={getAttachmentImage(selectedAttachment)}
              alt={selectedAttachment?.name || "Attachment"}
              style={{ width: "100%", objectFit: "contain" }}
            />
          ) : (
            <Box sx={{ textAlign: "center", py: 4 }}>
              <InsertDriveFileIcon sx={{ fontSize: 64, color: "gray" }} />
              <Typography variant="body1">
                {selectedAttachment?.name || "Attachment"}
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AttachmentsSection;
