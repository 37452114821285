import { useState } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import RoleManagement from "./RoleManagement";
import ConfigurationSettings from "./ConfigurationSettings";
import UserManagement from "./Usermanagement";

export default function Settings() {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>

      <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 3 }}>
        <Tab label="Role Management" />
        <Tab label="User Management" />
        <Tab label="Configuration Settings" />
      </Tabs>

      {tabValue === 0 && <RoleManagement />}
      {tabValue === 1 && <UserManagement />}
      {tabValue === 2 && <ConfigurationSettings />}
    </Box>
  );
}
