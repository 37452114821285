import { createContext, useContext, useState } from "react";

const NotificationContext = createContext();

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  const showNotification = (title, message) => {
    setNotifications((prev) => [
      ...prev,
      { id: Date.now(), title, message, read: false },
    ]);
    setNotificationCount((prev) => prev + 1);
  };

  const markAllAsRead = () => {
    setNotifications((prev) => prev.map((notif) => ({ ...notif, read: true })));
    setNotificationCount(0);
  };

  const getUnreadNotifications = () =>
    notifications.filter((notif) => !notif.read);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        notificationCount,
        showNotification,
        markAllAsRead,
        getUnreadNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
