import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Divider,
  Typography,
  Chip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
} from "@mui/material";
import BackButton from "../../components/BackButton";
import MeasurementsInfo from "../../components/MeasurementInfo";
import LaboursInfo from "../../components/LaboursInfo";
import { useQuery } from "react-query";
import { fetchProjectbyId } from "../../services/projectApi";
import { fetchMeasurement } from "../../services/measurementApi";
import { fetchAssignedLabours } from "../../services/laboursApi";
import LoadingSpinner from "../../ui/LoadingSpinner";
import { getStatusColor } from "../../components/constants/constants";
import ApprovedQuotations from "../../components/forms/ApprovedQuotations";

const ProjectDetailsPage = () => {
  const { projectId } = useParams(); // Retrieve projectId from the URL
  const [activeTab, setActiveTab] = useState(0); // Track the active tab (0: Measurement, 1: Labours)
  const [openCloseDialog, setOpenCloseDialog] = useState(false);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Update active tab state
  };

  // Fetch project details by projectId using react-query
  const { data: project, isLoading: isLoadingProject } = useQuery(
    ["fetchProjectById", projectId],
    () => fetchProjectbyId(projectId),
    {
      staleTime: Infinity, // Cache indefinitely
      refetchOnWindowFocus: false, // Don't refetch on window focus
    }
  );

  // Fetch measurements by projectId using react-query
  const { data: measurementData, isLoading: isLoadingMeasurements } = useQuery(
    ["fetchMeasurement", projectId],
    () => fetchMeasurement(projectId),
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  // Fetch labours by projectId using react-query
  const { data: laboursData, isLoading: isLaboursLoading } = useQuery(
    ["fetchAssignedLabours", projectId],
    () => fetchAssignedLabours(projectId),
    {
      staleTime: Infinity, // Cache indefinitely
      refetchOnWindowFocus: false, // Don't refetch on window focus
      enabled: activeTab === 1,
    }
  );

  const handleConfirmCloseProject = () => {
    setOpenCloseDialog(false);
  };

  if (isLoadingProject || isLoadingMeasurements) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={{ paddingLeft: 2 }}>
      <BackButton />

      {/* Project Overview */}
      <Box
        sx={{
          marginBottom: 2,
          backgroundColor: "whitesmoke",
          padding: 1.5,
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#00796b" }}
          >
            {project?.projectName || "Unnamed Project"}
          </Typography>
          <Chip
            label={project?.projectStatus || "Status Unknown"}
            sx={{ backgroundColor: getStatusColor(project?.projectStatus) }}
          />
        </Box>

        <Box sx={{ marginLeft: 4 }}>
          <Typography variant="body2">
            <strong>Customer:</strong> {project?.customer?.name || "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>Contact:</strong>{" "}
            {project?.customer?.contactNumber || "N/A"}
          </Typography>
          <Typography variant="body2">
            <strong>Email:</strong> {project?.customer?.email || "N/A"}
          </Typography>
        </Box>
      </Box>

      {/* Confirmation Dialog for Closing Project */}
      <Dialog open={openCloseDialog} onClose={() => setOpenCloseDialog(false)}>
        <DialogTitle>Confirm Project Closure</DialogTitle>
        <DialogContent>
          Are you sure you want to close this project?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCloseDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmCloseProject} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Tabs for Measurements and Labours */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        sx={{ marginBottom: 2 }}
      >
        <Tab label="Measurements" />
        <Tab label="Labours" />
        <Tab label="Approved Quotations" />
      </Tabs>

      <Divider sx={{ my: 1.5 }} />

      {/* Tab Content Rendering */}
      {activeTab === 0 && (
        <MeasurementsInfo
          measurementDetails={measurementData}
          projectId={projectId}
        />
      )}
      {activeTab === 1 &&
        (isLaboursLoading ? (
          <LoadingSpinner />
        ) : (
          <LaboursInfo laboursData={laboursData} projectId={projectId} />
        ))}
      {activeTab === 2 && <ApprovedQuotations projectId={projectId} />}
    </Box>
  );
};

export default ProjectDetailsPage;
