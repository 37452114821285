import apiClient from "./apiClient";

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

const notificationApi = {
  /**
   * Registers a device token for a specific user.
   *
   * @param {Object} payload - The request payload containing userId and token.
   * @param {string} payload.userId - UUID of the user.
   * @param {string} payload.token - FCM token for the device.
   * @returns {Promise} - Axios response promise.
   */
  registerDeviceToken: async (payload) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL}/notifications/register`,
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error registering device token:", error);
      throw error;
    }
  },

  /**
   * Sends a push notification to a user.
   *
   * @param {Object} payload - The request payload for sending a notification.
   * @param {string} payload.userId - UUID of the recipient user.
   * @param {string} payload.message - The notification message content.
   * @param {string} payload.title - The title of the notification.
   * @returns {Promise} - Axios response promise.
   */
  sendNotification: async (payload) => {
    try {
      const response = await apiClient.post(
        `${BASE_URL}/notifications/send`,
        payload
      );
      return response.data;
    } catch (error) {
      console.error("Error sending notification:", error);
      throw error;
    }
  },
};

export default notificationApi;
