import {
  fetchCategoryPricing,
  fetchCoatingOptions,
  createMeasurementCategory,
  updateMeasurementCategory,
  deleteMeasurementCategory,
  createCoatingOption,
  updateCoatingOption,
  deleteCoatingOption,
} from "../../services/measurementApi";

export const configurationMap = {
  measurementCategory: {
    // Column definitions for Measurement Categories
    columns: [
      {
        field: "categoryName",
        headerName: "Category Name",
        editable: true,
        flex: 1,
      },
      {
        field: "interiorPricePerSqft",
        headerName: "Interior Price (sqft)",
        editable: true,
        flex: 1,
      },
      {
        field: "exteriorPricePerSqft",
        headerName: "Exterior Price (sqft)",
        editable: true,
        flex: 1,
      },
    ],
    fetchData: fetchCategoryPricing, // API call to fetch data
    createData: createMeasurementCategory, // API call to create a category
    updateData: updateMeasurementCategory, // API call to update a category
    deleteData: deleteMeasurementCategory, // API call to delete a category
  },
  coatingOptions: {
    // Column definitions for Coating Options
    columns: [
      {
        field: "coatingType",
        headerName: "Coating Type",
        editable: true,
        flex: 1,
        disableInEditMode: true, // Custom property for non-editable fields during edit
      },
      {
        field: "pricePerSqft",
        headerName: "Price (sqft)",
        editable: true,
        flex: 1,
      },
    ],
    fetchData: fetchCoatingOptions, // API call to fetch coating options
    createData: createCoatingOption, // API call to create a coating option
    updateData: updateCoatingOption, // API call to update a coating option
    deleteData: deleteCoatingOption, // API call to delete a coating option
  },
};
