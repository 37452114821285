import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  Box,
  Typography,
} from "@mui/material";

export default function UserForm({
  isOpen,
  onClose,
  onSubmit,
  initialValues,
  mode,
  roles,
}) {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    roles: [],
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (initialValues) {
      setFormValues(initialValues);
    } else {
      // Reset form values on open for Add mode
      setFormValues({
        firstName: "",
        lastName: "",
        mobileNumber: "",
        email: "",
        roles: [],
      });
    }
  }, [initialValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleRolesChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormValues((prev) => ({
      ...prev,
      roles: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleValidation = () => {
    const errors = {};
    if (!formValues.firstName.trim()) {
      errors.firstName = "First Name is required.";
    }
    if (!formValues.lastName.trim()) {
      errors.lastName = "Last Name is required.";
    }
    if (!formValues.mobileNumber.trim()) {
      errors.mobileNumber = "Mobile Number is required.";
    }
    if (!formValues.email.trim()) {
      errors.email = "Email is required.";
    }
    if (!formValues.roles.length) {
      errors.roles = "At least one role must be assigned.";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (handleValidation()) {
      onSubmit(formValues);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{mode === "Add" ? "Add User" : "Edit User"}</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            value={formValues.firstName}
            onChange={handleChange}
            error={!!validationErrors.firstName}
            helperText={validationErrors.firstName}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            value={formValues.lastName}
            onChange={handleChange}
            error={!!validationErrors.lastName}
            helperText={validationErrors.lastName}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Mobile Number"
            name="mobileNumber"
            value={formValues.mobileNumber}
            onChange={handleChange}
            error={!!validationErrors.mobileNumber}
            helperText={validationErrors.mobileNumber}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={formValues.email}
            onChange={handleChange}
            error={!!validationErrors.email}
            helperText={validationErrors.email}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="roles-label">Roles</InputLabel>
            <Select
              labelId="roles-label"
              multiple
              value={formValues.roles}
              onChange={handleRolesChange}
              renderValue={(selected) => selected.join(", ")}
              error={!!validationErrors.roles}
            >
              {roles?.map((role) => (
                <MenuItem key={role.id} value={role.roleName}>
                  <Checkbox
                    checked={formValues.roles.includes(role.roleName)}
                  />
                  <ListItemText primary={role.roleName} />
                </MenuItem>
              ))}
            </Select>
            {validationErrors.roles && (
              <Typography
                variant="caption"
                color="error"
                sx={{ mt: 0.5, display: "block" }}
              >
                {validationErrors.roles}
              </Typography>
            )}
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {mode === "Add" ? "Add User" : "Save Changes"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
