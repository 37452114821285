import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import DashboardLayout from "./containers/dashboardLayout";
import DashboardPage from "./pages/DashboardPage";
import TasksPage from "./pages/TaskManagement/TasksPage";
import LabourSheetPage from "./pages/LabourManagement/LabourSheetPage";
import HelpCenterPage from "./pages/Support/HelpCenterPage";
import ContactSupportPage from "./pages/Support/ContactSupportPage";
import ProjectDetailsPage from "./pages/ProjectManagement/ProjectsDetailPage";
import Settings from "./pages/Settings/Settings";
import Expenses from "./pages/ExpenseManagement/Expenses";
import Reports from "./pages/ReportsAndAnalytics/Reports";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CustomerDetails from "./pages/CustomerManagement/CustomerDetails";
import { AuthProvider, useAuth } from "./context/AuthContext";
import LoginPage from "./pages/Login/LoginPage";
import PrivateRoute from "./components/PrivateRoute";
import AllProjectsPage from "./pages/ProjectManagement/AllProjectsPage";
import { NotificationProvider } from "./context/NotificationContext";

const theme = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          color: "#333",
          boxShadow: "none",
        },
      },
    },
  },
});

const LandingRedirect = () => {
  const { user } = useAuth();

  if (user?.roles.includes("Admin")) {
    return <Navigate to="/projects" replace />;
  }

  return <Navigate to="/dashboard" replace />;
};

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <Router>
          <AuthProvider>
            <NotificationProvider>
              <Routes>
                {/* Default landing based on role */}
                <Route path="/" element={<LandingRedirect />} />

                {/* Public Routes */}
                <Route path="/login" element={<LoginPage />} />

                {/* Main Layout with Nested Routes */}
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <DashboardLayout />
                    </PrivateRoute>
                  }
                >
                  <Route path="dashboard" element={<DashboardPage />} />
                  <Route path="customers" element={<CustomerDetails />} />
                  <Route path="projects" element={<AllProjectsPage />} />
                  <Route
                    path="projects/details/:projectId"
                    element={<ProjectDetailsPage />}
                  />
                  <Route path="tasks" element={<TasksPage />} />
                  <Route path="labours" element={<LabourSheetPage />} />
                  <Route path="expenses" element={<Expenses />} />
                  <Route path="reports" element={<Reports />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="help-center" element={<HelpCenterPage />} />
                  <Route
                    path="contact-support"
                    element={<ContactSupportPage />}
                  />
                </Route>
              </Routes>
            </NotificationProvider>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
