// src/pages/DashboardPage.jsx
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// Sample data for summary cards
const summaryData = {
  ongoingProjects: 8,
  completedProjects: 45,
  revenue: 125000,
  pendingInvoices: 15,
  activeTeamMembers: 22,
};

// Sample data for the charts
const chartData = [
  { name: "Jan", revenue: 10000, projects: 2 },
  { name: "Feb", revenue: 15000, projects: 4 },
  { name: "Mar", revenue: 20000, projects: 6 },
  { name: "Apr", revenue: 18000, projects: 5 },
  { name: "May", revenue: 22000, projects: 7 },
  { name: "Jun", revenue: 25000, projects: 8 },
  { name: "Jul", revenue: 30000, projects: 9 },
];

// Sample notifications
const notifications = [
  "Overdue task: Project ABC needs attention.",
  "Low inventory alert: White paint is running low.",
  "Pending approval: Client quote for Project XYZ.",
];

const DashboardPage = () => {
  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      {/* Header */}
      <Typography variant="h4" gutterBottom>
        Dashboard Overview
      </Typography>

      {/* Summary Cards */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary">
                Ongoing Projects
              </Typography>
              <Typography variant="h3" component="div">
                {summaryData.ongoingProjects}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary">
                Completed Projects
              </Typography>
              <Typography variant="h3" component="div">
                {summaryData.completedProjects}
              </Typography>
              <AssessmentIcon color="primary" />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary">
                Revenue
              </Typography>
              <Typography variant="h3" component="div">
                ${summaryData.revenue}
              </Typography>
              <TrendingUpIcon color="success" />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary">
                Pending Invoices
              </Typography>
              <Typography variant="h3" component="div">
                {summaryData.pendingInvoices}
              </Typography>
              <NotificationsActiveIcon color="error" />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="text.secondary">
                Active Team Members
              </Typography>
              <Typography variant="h3" component="div">
                {summaryData.activeTeamMembers}
              </Typography>
              <PeopleAltIcon color="primary" />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts Section */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Revenue and Project Trends
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="revenue"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="projects" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      </Box>

      {/* Notifications Section */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Notifications and Alerts
        </Typography>
        {notifications.map((notification, index) => (
          <Card key={index} sx={{ mb: 2 }}>
            <CardContent>
              <Typography color="text.secondary">{notification}</Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Quick Links */}
      <Box sx={{ display: "flex", gap: 2, mt: 4 }}>
        <Button variant="contained" color="primary">
          Add New Project
        </Button>
        <Button variant="contained" color="secondary">
          Manage Clients
        </Button>
        <Button variant="contained" color="success">
          Schedule Tasks
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardPage;
