// services/laboursApi.js
import moment from "moment";
import apiClient from "./apiClient";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

export const fetchLabours = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/labours`);
  return response.data.data;
};

export const createLabour = async (labourData) => {
  const response = await apiClient.post(`${API_BASE_URL}/labours`, labourData);
  return response.data.data;
};

export const updateLabour = async (labourData) => {
  const response = await apiClient.put(
    `${API_BASE_URL}/labours/${labourData.id}`,
    labourData
  );
  return response.data.data;
};

export const deleteLabour = async (labourId) => {
  const response = await apiClient.delete(
    `${API_BASE_URL}/labours/${labourId}`
  );
  return response.data.data;
};

export const getLabourDetails = async (labourId) => {
  const response = await apiClient.get(`${API_BASE_URL}/labours/${labourId}`);
  return response.data.data;
};

export const fetchAssignedLabours = async (projectId) => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/project-labours/project/${projectId}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching assigned labours:", error);
    throw error;
  }
};

export const fetchAttendanceByProjectDateRange = async (
  projectId,
  startDate,
  endDate
) => {
  // Format dates to 'yyyy-MM-dd'
  const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
  const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

  const response = await apiClient.get(`${API_BASE_URL}/attendance/summary`, {
    params: {
      projectId: projectId,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    },
  });
  return response.data;
};

export const fetchDailyAttendanceByDate = async (projectId, date) => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/attendance/project/${projectId}`,
      {
        params: { date }, // Passing the date as a query parameter
      }
    );
    return response.data.data;
  } catch (error) {
    throw new Error("Failed to fetch attendance for the specified date", error);
  }
};

export const fetchAttendanceByLabour = async (projectId, labourId) => {
  try {
    const response = await apiClient.get(
      `${API_BASE_URL}/attendance/project/${projectId}/labour/${labourId}`
    );
    return response.data;
  } catch (error) {
    throw new Error(
      "Failed to fetch attendance for the specific labour",
      error
    );
  }
};

export const assignLabourToProject = async (assignmentData) => {
  try {
    const response = await apiClient.post(
      `${API_BASE_URL}/project-labours/assign`,
      assignmentData
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to assign labour to project", error);
  }
};

export const updatePaidStatus = async (request) => {
  const response = await apiClient.post(
    `${API_BASE_URL}/attendance/update-paid-status`,
    request
  );
  return response.data;
};

export const unAssignLabour = async (projectLabourId) => {
  try {
    const response = await apiClient.delete(
      `${API_BASE_URL}/project-labours/unassign`,
      {
        params: { projectLabourId },
      }
    );
    return response.data.data;
  } catch (error) {
    throw new Error("Failed to unassign labour from project", error);
  }
};

export const updateAttendancePaidStatus = async ({
  attendanceId,
  paidStatus,
}) => {
  const response = await apiClient.put(
    `${API_BASE_URL}/attendance/${attendanceId}/mark-paid`,
    null, // No request body
    {
      params: { paidStatus },
    }
  );
  return response.data.data; // Assuming API wraps data under 'data'
};
