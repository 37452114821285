import apiClient from "./apiClient";

export const API_URL = import.meta.env.VITE_API_BASE_URL;

export const fetchCustomers = async () => {
  const response = await apiClient.get(`${API_URL}/customers`);
  return response.data.data;
};

export const createCustomer = async (customerData) => {
  try {
    const response = await apiClient.post(`${API_URL}/customers`, {
      name: customerData.name,
      contactNumber: customerData.contactNumber,
      email: customerData.email,
      address: customerData.address,
      city: customerData.city,
      postalCode: customerData.postalCode,
      leadSource: customerData.leadSource?.toUpperCase(),
      otherLeadSource:
        customerData.leadSource === "OTHER"
          ? customerData.otherLeadSource?.toUpperCase()
          : null,
      notes: customerData.notes,
    });
    return response.data; // Return the created customer response
  } catch (error) {
    throw new Error(error.response.data.message || "Failed to create customer");
  }
};

// Edit (update) an existing customer
export const updateCustomer = async (customerData) => {
  try {
    const response = await apiClient.put(
      `${API_URL}/customers/${customerData.id}`,
      {
        name: customerData.name,
        contactNumber: customerData.contactNumber,
        email: customerData.email,
        address: customerData.address,
        city: customerData.city,
        postalCode: customerData.postalCode,
        leadSource: customerData.leadSource,
        otherLeadSource:
          customerData.leadSource === "OTHER"
            ? customerData.otherLeadSource
            : null,
        notes: customerData.notes,
      }
    );
    return response.data; // Return the updated customer response
  } catch (error) {
    throw new Error(error.response.data.message || "Failed to update customer");
  }
};

// Delete a customer by ID
export const deleteCustomer = async (customerId) => {
  try {
    const response = await apiClient.delete(
      `${API_URL}/customers/${customerId}`
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || "Failed to delete customer");
  }
};

export const getProjectsByCustomerId = async (customerId) => {
  const response = await apiClient.get(
    `${API_URL}/projects/${customerId}/projects`
  );
  return response.data.data;
};
