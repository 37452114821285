import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
  IconButton,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AttachmentsSection from "../ui/AttachmentSection";
import HistoryButton from "../ui/HistoryButton";
import MeasurementForm from "./forms/MeasurementForm";
import { useMutation, useQueryClient } from "react-query";
import {
  createMeasurement,
  updateMeasurement,
  fetchCategoryPricing,
  fetchCoatingOptions,
  submitMeasurementForApproval,
  approveMeasurement,
  requestMeasurementRevisions,
} from "../services/measurementApi";
import { useQuery } from "react-query";
import { ApprovalStatus } from "./constants/constants";
import ApprovalSection from "../ui/ApprovalSection";
import QuotationForm from "./forms/QuotationForm";
import LoadingSpinner from "../ui/LoadingSpinner";

const MeasurementsInfo = ({ measurementDetails, projectId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [status, setStatus] = useState(ApprovalStatus.DRAFT);
  const [comments, setComments] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const queryClient = useQueryClient();

  const [measurements, setMeasurements] = useState(
    measurementDetails || { rooms: [], notes: "", totalPaintableArea: 0 }
  );

  const [openQuotationDialog, setOpenQuotationDialog] = useState(false);

  const handleViewQuotation = () => setOpenQuotationDialog(true);
  const handleCloseQuotationDialog = () => setOpenQuotationDialog(false);

  useEffect(() => {
    if (measurementDetails) {
      setMeasurements(measurementDetails);
      const statusFromDetails =
        measurementDetails?.approvalStatus || ApprovalStatus.DRAFT;
      setStatus(statusFromDetails);
      if (measurementDetails?.approveComments) {
        setComments(measurementDetails.approveComments);
      }
      setIsVerifying(statusFromDetails === ApprovalStatus.DRAFT);
    }
  }, [measurementDetails]);

  const { data: coatingOptions, isLoading: isFetching } = useQuery(
    "coatingOptions",
    fetchCoatingOptions,
    {
      staleTime: Infinity, // Cache indefinitely
      refetchOnWindowFocus: false, // Don't refetch on window focus
    }
  );

  const {
    data: categoryPricing,
    isError,
    error,
  } = useQuery("categoryPricing", fetchCategoryPricing, {
    refetchOnWindowFocus: false,
  });

  if (isError) {
    console.error("Error fetching category pricing:", error.message);
  }

  const createMeasurementMutation = useMutation(createMeasurement, {
    onSuccess: () => {
      queryClient.invalidateQueries(["fetchMeasurement", projectId]);
      setIsEditing(false);
      setIsVerifying(false);
    },
    onError: (error) => {
      console.error("Error creating measurement:", error);
    },
  });

  const updateMeasurementMutation = useMutation(updateMeasurement, {
    onSuccess: () => {
      queryClient.invalidateQueries(["fetchMeasurement", projectId]);
      setIsEditing(false);
      setIsVerifying(false);
    },
    onError: (error) => {
      console.error("Error updating measurement:", error);
    },
  });

  const submitForApprovalMutation = useMutation(submitMeasurementForApproval, {
    onSuccess: () => {
      queryClient.invalidateQueries(["fetchMeasurement", projectId]);
      setIsVerifying(false);
      setStatus(ApprovalStatus.PENDING_APPROVAL);
    },
    onError: (error) => {
      console.error("Error submitting measurement for approval:", error);
      setErrorMessage("Failed to submit measurement for approval");
    },
  });

  const approveMeasurementMutation = useMutation(approveMeasurement, {
    onSuccess: () => {
      queryClient.invalidateQueries(["fetchMeasurement", projectId]);
      setComments(measurementDetails?.approvalComments || "");
      setStatus(ApprovalStatus.APPROVED);
      setIsVerifying(false);
    },
    onError: (error) => {
      console.error("Error approving measurement:", error);
      setErrorMessage("Failed to approve the measurement");
    },
  });

  const requestRevisionsMutation = useMutation(requestMeasurementRevisions, {
    onSuccess: () => {
      queryClient.invalidateQueries(["fetchMeasurement", projectId]);
      setComments(measurementDetails?.approvalComments || "");
      setStatus(ApprovalStatus.REVISIONS_NEEDED);
      setIsVerifying(false);
    },
    onError: (error) => {
      console.error("Error requesting revisions for measurement:", error);
      setErrorMessage("Failed to request revisions for the measurement");
    },
  });

  const isLoading =
    createMeasurementMutation.isLoading ||
    updateMeasurementMutation.isLoading ||
    submitForApprovalMutation.isLoading ||
    approveMeasurementMutation.isLoading ||
    requestRevisionsMutation.isLoading;

  if (isLoading || isFetching) {
    return <LoadingSpinner />;
  }

  const handleEdit = () => setIsEditing(true);

  const preparePayload = () => {
    const formattedRooms = measurements.rooms.map((room) => ({
      ...room,
      measurementCategoryId:
        room.measurementCategory?.id || room.measurementCategoryId,
      coatings: room.coatings.map((coating) => ({
        coatingOption: coating.coatingOption
          ? {
              id: coating.coatingOption.id,
              coatingType: coating.coatingOption.coatingType,
            }
          : null,
        numberOfCoats: coating.numberOfCoats,
      })),
    }));

    return {
      ...measurements,
      rooms: formattedRooms,
    };
  };

  const handleVerify = () => {
    // Prepare the payload to ensure it's properly formatted
    const payload = preparePayload(); // Ensure data matches backend expectations
    setIsVerifying(true);
    setStatus(ApprovalStatus.DRAFT);

    if (measurements?.id) {
      // Update existing measurement
      updateMeasurementMutation.mutate(
        {
          measurementId: measurements.id,
          data: {
            ...payload,
            approvalStatus: ApprovalStatus.DRAFT,
            projectId,
          },
        },
        {
          onSuccess: () => {
            // Reset states after successful update
            setIsEditing(false);
            setIsVerifying(false);
          },
          onError: (error) => {
            console.error("Error updating measurement:", error);
            setIsVerifying(false);
          },
        }
      );
    } else {
      // Create a new measurement
      createMeasurementMutation.mutate(
        { ...payload, approvalStatus: ApprovalStatus.DRAFT, projectId },
        {
          onSuccess: () => {
            // Reset states after successful creation
            setIsEditing(false);
            setIsVerifying(false);
          },
          onError: (error) => {
            console.error("Error creating measurement:", error);
            setIsVerifying(false);
          },
        }
      );
    }
  };

  const handleSendForApproval = () => {
    setStatus(ApprovalStatus.PENDING_APPROVAL);
    setIsVerifying(false);
    if (measurements?.id) {
      submitForApprovalMutation.mutate(measurements.id);
    }
  };

  const handleApprove = () => {
    if (measurements?.id) {
      approveMeasurementMutation.mutate({
        measurementId: measurements.id,
        comments: comments,
      });
    }
  };

  const handleRequestRevisions = () => {
    if (measurements?.id) {
      requestRevisionsMutation.mutate({
        measurementId: measurements.id,
        comments: comments,
      });
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const getRoomTotalPaintableArea = (room) => {
    return room?.totalPaintableArea || 0;
  };

  return (
    <>
      <Card sx={{ mt: 3, boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" color="primary">
              Measurement Information
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Chip
                label={status}
                color={
                  status === ApprovalStatus.APPROVED
                    ? "success"
                    : status === ApprovalStatus.REVISIONS_NEEDED
                    ? "error"
                    : "default"
                }
                size="small"
              />
              {measurementDetails?.id ? (
                <>
                  <IconButton
                    onClick={handleEdit}
                    color="primary"
                    aria-label="edit"
                  >
                    <EditIcon />
                  </IconButton>
                  <HistoryButton onClick={() => setOpenHistoryDialog(true)} />
                  <Button href="#text-buttons" onClick={handleViewQuotation}>
                    View Quotation
                  </Button>
                </>
              ) : (
                <Button
                  startIcon={<AddIcon />}
                  variant="contained"
                  onClick={handleEdit}
                  color="primary"
                >
                  Create
                </Button>
              )}
            </Box>
          </Box>
          <Divider sx={{ mb: 2 }} />

          <QuotationForm
            measurementId={measurementDetails?.id}
            open={openQuotationDialog}
            onClose={handleCloseQuotationDialog}
          />

          {isEditing ? (
            <>
              <MeasurementForm
                measurements={measurements}
                setMeasurements={setMeasurements}
                coatingOptions={coatingOptions}
                categoryPricing={categoryPricing}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleCancelEdit}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleVerify}
                >
                  Verify
                </Button>
              </Box>
            </>
          ) : (
            <>
              <TableContainer
                component={Paper}
                sx={{ boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}
              >
                <Table>
                  <TableHead sx={{ backgroundColor: "#f4f4f4" }}>
                    <TableRow>
                      <TableCell>
                        <strong>Room Type</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Category</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Dimensions (L × B × H)</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Ceiling</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Exclusions</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Extra Areas</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Coatings</strong>
                      </TableCell>
                      <TableCell align="right">
                        <strong>Paintable Area (sq ft)</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {measurements.rooms.map((room, index) => (
                      <TableRow
                        key={index}
                        sx={{ "&:hover": { backgroundColor: "#f9f9f9" } }}
                      >
                        <TableCell>
                          {room.roomType || `Room ${index + 1}`}
                          <Divider />
                          {room.roomScope}
                        </TableCell>
                        <TableCell>
                          {room.measurementCategory?.categoryName || "N/A"}
                        </TableCell>
                        <TableCell>
                          {room.length || "N/A"} ft × {room.breadth || "N/A"} ft
                          × {room.height || "N/A"} ft
                        </TableCell>
                        <TableCell>
                          {room.includeCeiling ? "Yes" : "No"}
                        </TableCell>
                        <TableCell>
                          {room.exclusions?.length > 0 ? (
                            room.exclusions.map((exclusion, i) => {
                              // Prepare dimensions string by filtering out undefined values
                              const dimensions = [
                                exclusion.length
                                  ? `${exclusion.length} ft`
                                  : null,
                                exclusion.breadth
                                  ? `${exclusion.breadth} ft`
                                  : null,
                                exclusion.height
                                  ? `${exclusion.height} ft`
                                  : null,
                              ]
                                .filter(Boolean) // Remove any null values from the array
                                .join(" x "); // Join available dimensions with " x " separator

                              return (
                                <Typography key={i} variant="body2">
                                  {exclusion.exclusionType} -{" "}
                                  {dimensions || "N/A"}
                                </Typography>
                              );
                            })
                          ) : (
                            <Typography>-</Typography>
                          )}
                        </TableCell>

                        <TableCell>
                          {room.extraAreas?.length > 0 ? (
                            room.extraAreas.map((extra, i) => {
                              // Filter out dimensions that are not provided
                              const dimensions = [
                                extra.length ? `${extra.length} ft` : null,
                                extra.breadth ? `${extra.breadth} ft` : null,
                                extra.height ? `${extra.height} ft` : null,
                              ]
                                .filter(Boolean) // Remove null values
                                .join(" x "); // Join the available dimensions

                              return (
                                <Typography key={i} variant="body2">
                                  {extra.areaType} - {dimensions || "N/A"}
                                </Typography>
                              );
                            })
                          ) : (
                            <Typography>-</Typography>
                          )}
                        </TableCell>

                        <TableCell>
                          {room.coatings?.length > 0 ? (
                            room.coatings.map((coat, i) => (
                              <Typography key={i} variant="body2">
                                {coat?.coatingType} - {coat.numberOfCoats}
                              </Typography>
                            ))
                          ) : (
                            <Typography>-</Typography>
                          )}
                        </TableCell>

                        <TableCell align="right">
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", color: "#1976d2" }}
                          >
                            {getRoomTotalPaintableArea(room)} sq ft
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  mt: 2,
                  gap: 1,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", color: "#666", fontSize: "0.9rem" }}
                >
                  Total Paintable Area:
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#1976d2" }}
                >
                  {measurements?.totalAreaSqft} sq ft
                </Typography>
              </Box>

              <AttachmentsSection
                attachments={measurements.attachments || []}
              />
              {isVerifying && (
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendForApproval}
                  >
                    Send for Approval
                  </Button>
                </Box>
              )}
            </>
          )}
          {!isEditing && (
            <ApprovalSection
              status={status}
              savedComments={measurements?.approvalComments || ""}
              setComments={setComments}
              errorMessage={errorMessage}
              handleApprove={handleApprove}
              handleReject={handleRequestRevisions}
            />
          )}
        </CardContent>
      </Card>
      {/* <HistoryModal
        open={openHistoryDialog}
        onClose={() => setOpenHistoryDialog(false)}
        history={history}
        title="Measurement History"
      /> */}
    </>
  );
};

export default MeasurementsInfo;
