import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  IconButton,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useQuery, useMutation, useQueryClient } from "react-query";
import CustomerForm from "../../components/forms/CustomerForm";
import {
  fetchCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getProjectsByCustomerId,
} from "../../services/customerApi";
import LoadingSpinner from "../../ui/LoadingSpinner";
import CustomerFullDisplay from "./CustomerfullDisplay";
import {
  getStatusColor,
  projectStatuses,
} from "../../components/constants/constants.js";

const CustomerDetails = () => {
  const queryClient = useQueryClient();

  const {
    data: customers = [],
    isLoading,
    error,
  } = useQuery("customers", fetchCustomers);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [customerProjects, setCustomerProjects] = useState([]);
  const [errors, setErrors] = useState({});

  const { mutate: addCustomer, isLoading: isCreating } = useMutation(
    createCustomer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("customers");
      },
    }
  );

  const { mutate: editCustomer, isLoading: isUpdating } = useMutation(
    updateCustomer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("customers");
      },
    }
  );

  const { mutate: removeCustomer, isLoading: isRemoving } = useMutation(
    deleteCustomer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("customers");
      },
    }
  );

  const fetchCustomerProjects = async (customerId) => {
    try {
      const projects = await getProjectsByCustomerId(customerId);
      setCustomerProjects(projects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleOpenFormDialog = (customer = null) => {
    setSelectedCustomer(customer ? { ...customer } : {});
    setErrors({});
    setOpenDialog(true);
  };

  const handleCloseFormDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDeleteDialog = (customer) => {
    setCustomerToDelete(customer);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = () => {
    if (customerToDelete) {
      removeCustomer(customerToDelete.id);
      setOpenDeleteDialog(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const data = selectedCustomer || {};

    if (!data.name) newErrors.name = "Name is required";
    if (!data.contactNumber) newErrors.contactNumber = "Contact is required";
    if (!data.email) newErrors.email = "Email is required";
    if (!data.address) newErrors.address = "Address is required";
    if (!data.city) newErrors.city = "City is required";
    if (!data.postalCode) newErrors.postalCode = "Postal Code is required";
    if (!data.leadSource) newErrors.leadSource = "Lead Source is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (selectedCustomer.id) {
        editCustomer(selectedCustomer);
      } else {
        addCustomer(selectedCustomer);
      }
      setOpenDialog(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRowClick = (params) => {
    const customer = params.row;
    setSelectedCustomer(customer);
    fetchCustomerProjects(customer.id);
  };

  const filteredCustomers = customers.filter((customer) =>
    customer.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading || isCreating || isUpdating || isRemoving) {
    return <LoadingSpinner />;
  }

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "contactNumber", headerName: "Contact", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "city", headerName: "City", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton
            color="primary"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenFormDialog(params.row);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              handleOpenDeleteDialog(params.row);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  if (isLoading) return <LoadingSpinner />;
  if (error)
    return <Typography>Error loading customers: {error.message}</Typography>;

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#1976d2" }}>
          Customers
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          size="small"
          onClick={() => handleOpenFormDialog(null)}
        >
          Add Customer
        </Button>
      </Box>

      <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
        <TextField
          variant="outlined"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ maxWidth: 300, borderRadius: "8px" }}
          size="small"
        />
        <IconButton>
          <SearchIcon />
        </IconButton>
      </Box>

      <Box sx={{ overflowX: "auto" }}>
        <DataGrid
          rows={filteredCustomers}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          autoHeight
          disableSelectionOnClick
          sortingOrder={["asc", "desc"]}
          onRowClick={handleRowClick}
          getRowHeight={() => "auto"}
        />
      </Box>

      {selectedCustomer && (
        <Box
          sx={{
            mt: 3,
            p: 3,
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
            boxShadow: "0 2px 12px rgba(0, 0, 0, 0.05)",
          }}
        >
          <CustomerFullDisplay selectedCustomer={selectedCustomer} />
          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" sx={{ mb: 2, color: "#1976d2" }}>
            Projects
          </Typography>
          {customerProjects.length > 0 ? (
            <DataGrid
              rows={customerProjects}
              columns={[
                { field: "projectName", headerName: "Project Name", flex: 1 },
                { field: "startDate", headerName: "Start Date", flex: 1 },
                { field: "estimatedEndDate", headerName: "End Date", flex: 1 },
                {
                  field: "projectStatus",
                  headerName: "Status",
                  flex: 1,
                  renderCell: (params) => {
                    const status = projectStatuses.find(
                      (status) => status.value === params.value
                    );
                    const label = status ? status.label : params.value;

                    return (
                      <Box
                        sx={{
                          color: getStatusColor(params.value),
                          padding: "2px 2px",
                          borderRadius: "1px",
                          textAlign: "center",
                          display: "inline-block",
                          fontWeight: "bold",
                        }}
                      >
                        {label}
                      </Box>
                    );
                  },
                },
                { field: "budget", headerName: "Budget", flex: 1 },
              ]}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              autoHeight
              disableSelectionOnClick
              getRowHeight={() => "auto"}
            />
          ) : (
            <Typography>No past projects found.</Typography>
          )}
        </Box>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCloseFormDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedCustomer?.id ? "Edit Customer" : "Add Customer"}
        </DialogTitle>
        <DialogContent>
          <CustomerForm
            customerInfo={selectedCustomer}
            setCustomerInfo={setSelectedCustomer}
            errors={errors}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFormDialog} color="error">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete {customerToDelete?.name}?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="error">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CustomerDetails;
