// notificationService.js
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase";

let listenerInitialized = false;

export const onMessageListener = (callback) => {
  if (!listenerInitialized) {
    onMessage(messaging, (payload) => {

      // Use Notification API if permission granted
      const notificationTitle =
        payload.notification?.title || payload.data?.title;
      const notificationOptions = {
        body: payload.notification?.body || payload.data?.body,
        icon: payload.notification?.icon || payload.data?.image,
      };

      if (Notification.permission === "granted") {
        new Notification(notificationTitle, notificationOptions);
      }

      // Pass payload to provided callback
      if (callback) {
        callback(payload);
      }
    });

    listenerInitialized = true; // Prevent re-initialization
  }
};
