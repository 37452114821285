import { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Autocomplete } from "@mui/material";
import { assignLabourToProject, fetchLabours } from "../../services/laboursApi";
import LoadingSpinner from "../../ui/LoadingSpinner";
import { id } from "date-fns/locale";

const AssignLabourForm = ({ open, onClose, projectId }) => {
  const [selectedLabour, setSelectedLabour] = useState(null);
  const [assignmentDate, setAssignmentDate] = useState(new Date());
  const [wagePerDay, setWagePerDay] = useState("");
  const queryClient = useQueryClient();

  const {
    data: labours,
    isLoading: isLaboursLoading,
    refetch: refetchLabours,
  } = useQuery("availableLabours", fetchLabours, {
    enabled: false, // Fetch only when needed
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
  });

  useQuery(() => {
    console.log("labours", labours);
  }, [labours]);

  // Assign labour mutation
  const mutation = useMutation(assignLabourToProject, {
    onSuccess: () => {
      queryClient.invalidateQueries(["fetchAssignedLabours", projectId]);
      queryClient.invalidateQueries("availableLabours");
      refetchLabours();
      onClose(); // Close modal after successful mutation
    },
    onError: (err) => {
      console.error("Failed to assign labour:", err.message);
    },
  });

  useEffect(() => {
    if (open) {
      refetchLabours();
    }
  }, [open, refetchLabours]);

  const handleAssign = () => {
    if (!selectedLabour || !assignmentDate) {
      alert("Please fill all fields before assigning.");
      return;
    }

    mutation.mutate({
      projectId,
      labourId: selectedLabour.id,
      assignmentDate: assignmentDate.toISOString().split("T")[0], // Convert to YYYY-MM-DD format
      wagePerDay: parseFloat(wagePerDay),
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          p: 4,
          borderRadius: "8px",
          boxShadow: 24,
        }}
      >
        <Typography variant="h6" mb={2}>
          Assign Labour to Project
        </Typography>
        <Box mb={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Assignment Date"
              value={assignmentDate}
              onChange={(newValue) => setAssignmentDate(newValue)}
              slotProps={{
                textField: { fullWidth: true, variant: "outlined" },
              }}
            />
          </LocalizationProvider>
        </Box>
        <Autocomplete
          options={labours || []}
          getOptionLabel={(option) => option.userName || ""}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          loading={isLaboursLoading}
          onChange={(event, newValue) => setSelectedLabour(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Labour"
              variant="outlined"
              fullWidth
              margin="dense"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLaboursLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />

        <TextField
          label="Wage Per Day"
          type="number"
          value={wagePerDay}
          onChange={(e) => setWagePerDay(e.target.value)}
          fullWidth
          variant="outlined"
          margin="dense"
        />
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleAssign}
            variant="contained"
            disabled={!selectedLabour || mutation.isLoading}
          >
            {mutation.isLoading ? <LoadingSpinner /> : "Assign"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AssignLabourForm;
