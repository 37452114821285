import apiClient from "./apiClient";

const API_URL = import.meta.env.VITE_API_BASE_URL;

// Fetch all projects
export const fetchProjects = async () => {
  try {
    const response = await apiClient.get(`${API_URL}/projects`);
    return response.data.data; // Assuming the API response structure
  } catch (error) {
    throw new Error(error.response?.data?.message || "Error fetching projects");
  }
};

// Fetch all projects
export const fetchProjectbyId = async (projectId) => {
  try {
    const response = await apiClient.get(`${API_URL}/projects/${projectId}`);
    return response.data.data; // Assuming the API response structure
  } catch (error) {
    throw new Error(error.response?.data?.message || "Error fetching project");
  }
};

// Create a new project
export const createProject = async (projectData) => {
  try {
    const response = await apiClient.post(`${API_URL}/projects`, projectData);
    return response.data.data; // Assuming the API response structure
  } catch (error) {
    throw new Error(error.response?.data?.message || "Error creating project");
  }
};

// Update an existing project
export const updateProject = async (projectData) => {
  try {
    const response = await apiClient.put(
      `${API_URL}/projects/${projectData.id}`,
      projectData
    );
    return response.data.data; // Assuming the API response structure
  } catch (error) {
    throw new Error(error.response?.data?.message || "Error updating project");
  }
};

// Delete (Cancel) a project
export const deleteProject = async (projectId) => {
  try {
    const response = await apiClient.delete(`${API_URL}/projects/${projectId}`);
    return response.data.data; // Assuming the API response structure
  } catch (error) {
    throw new Error(error.response?.data?.message || "Error deleting project");
  }
};
