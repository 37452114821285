import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  AiFillDashboard,
  AiOutlineProject,
  AiOutlineTeam,
  AiFillSetting,
  AiFillAccountBook,
} from "react-icons/ai";
import { MdOutlineTask } from "react-icons/md";
import { BsGraphUp } from "react-icons/bs";
import { IoPersonCircle } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const drawerWidth = 260;
const headerHeight = 64; // Adjust based on your header's height

const SidebarContainer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    width: open ? drawerWidth : theme.spacing(9),
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    backgroundColor: "#f9fafc",
    border: "none",
    padding: "16px 0",
    marginTop: `${headerHeight}px`, // Push below the header
    height: `calc(100vh - ${headerHeight}px)`, // Ensure it fits below the header
  },
}));

const SidebarHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  justifyContent: open ? "flex-end" : "center",
  alignItems: "center",
  padding: theme.spacing(1),
  position: "sticky",
  top: 0,
  zIndex: 1100,
  backgroundColor: "#f9fafc",
}));

const SidebarCard = styled("div")({
  width: "100%",
  padding: "10px 0",
  flex: 1,
  overflowY: "auto",
});

const StyledListItemButton = styled(ListItemButton)({
  padding: "12px 20px",
  fontWeight: "bold",
  fontSize: "15px",
  fontFamily: "'Poppins', sans-serif",
  color: "#333",
  "&:hover": {
    backgroundColor: "#e3f2fd",
    borderRadius: "8px",
    transition: "background-color 0.3s ease-in-out",
  },
  textTransform: "none",
});

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 40,
  fontSize: "1.5rem",
});

const StyledListItemText = styled(ListItemText)(({ theme, open }) => ({
  "& .MuiTypography-root": {
    fontWeight: 500,
    fontSize: "14px",
    color: "#333",
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
    }),
    opacity: open ? 1 : 0,
  },
}));

const Sidebar = () => {
  const { user } = useAuth();
  const roles = user?.roles || ["guest"];
  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen((prev) => !prev);
  };

  const menuItems = [
    {
      to: "/",
      text: "Dashboard",
      icon: <AiFillDashboard style={{ color: "#007bff" }} />,
      roles: ["user"],
    },
    {
      to: "/customers",
      text: "Customers",
      icon: <IoPersonCircle style={{ color: "#3f5c65" }} />,
      roles: ["Admin"],
    },
    {
      to: "/projects",
      text: "Projects",
      icon: <AiOutlineProject style={{ color: "#673ab7" }} />,
      roles: ["Admin"],
    },
    {
      to: "/tasks",
      text: "Tasks",
      icon: <MdOutlineTask style={{ color: "#009688" }} />,
      roles: ["user"],
    },
    {
      to: "/labours",
      text: "Labours",
      icon: <AiOutlineTeam style={{ color: "#e91e63" }} />,
      roles: ["Admin"],
    },
    {
      to: "/expenses",
      text: "Expenses & Payments",
      icon: <AiFillAccountBook style={{ color: "#2196f3" }} />,
      roles: ["user"],
    },
    {
      to: "/reports",
      text: "Reports & Analytics",
      icon: <BsGraphUp style={{ color: "#795548" }} />,
      roles: ["user"],
    },
    {
      to: "/settings",
      text: "Settings",
      icon: <AiFillSetting style={{ color: "#9c27b0" }} />,
      roles: ["Admin", "user"],
    },
  ];

  return (
    <SidebarContainer variant="permanent" open={open}>
      <SidebarHeader open={open}>
        <IconButton onClick={handleDrawerToggle}>
          {open ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      </SidebarHeader>
      <SidebarCard>
        <List>
          {menuItems
            .filter((item) => item.roles.some((role) => roles.includes(role)))
            .map((item, index) => (
              <StyledListItemButton
                key={index}
                component={NavLink}
                to={item.to}
              >
                <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                <StyledListItemText primary={item.text} open={open} />
              </StyledListItemButton>
            ))}
        </List>
      </SidebarCard>
    </SidebarContainer>
  );
};

export default Sidebar;
