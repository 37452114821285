export const getStatusColor = (status) => {
  switch (status) {
    case "PENDING":
      return "#ff9800"; // Orange for pending
    case "IN_PROGRESS":
      return "#2196f3"; // Blue for in-progress
    case "ON_HOLD":
      return "#f44336"; // Red for on-hold
    case "COMPLETED":
      return "#4caf50"; // Green for completed
    case "CANCELED":
      return "#9e9e9e"; // Grey for canceled
    default:
      return "#607d8b"; // Default color for unknown status
  }
};

export const projectStatuses = [
  { label: "Pending", value: "PENDING" },
  { label: "In Progress", value: "IN_PROGRESS" },
  { label: "On Hold", value: "ON_HOLD" },
  { label: "Completed", value: "COMPLETED" },
  { label: "Canceled", value: "CANCELED" },
];

export const ApprovalStatus = {
  DRAFT: "DRAFT",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  APPROVED: "APPROVED",
  REVISIONS_NEEDED: "REVISIONS_NEEDED",
};

export const ExperienceLevels = [
  { value: "BEGINNER", label: "Beginner" },
  { value: "INTERMEDIATE", label: "Intermediate" },
  { value: "ADVANCED", label: "Advanced" },
  { value: "EXPERT", label: "Expert" },
];

export const WORKFLOW_STAGES = [
  { stage: "Measurement & Quotation", tag: "MEASUREMENT" },
  { stage: "Labours Assigned", tag: "LABOUR_WORK" },
  // { stage: "Final Review", tag: "REVIEW" },
  // { stage: "Payment Collection", tag: "PAYMENT" },
];
