import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useQuery } from "react-query";
import { fetchQuotationById } from "../../services/quotationApi";
import LoadingSpinner from "../../ui/LoadingSpinner";
import DownloadIcon from "@mui/icons-material/Download";
import { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ApprovedQuotationForm = ({ quotationId, open, onClose }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  // Fetch quotation data by quotationId
  const {
    data: quotationData,
    isLoading,
    error,
  } = useQuery(
    ["quotation", quotationId],
    () => fetchQuotationById(quotationId),
    { enabled: open && !!quotationId }
  );

  if (isLoading) return <LoadingSpinner />;
  if (error) return <Typography>Error loading quotation data</Typography>;

  const items = quotationData?.quotationItems || [];
  const totalAmount = quotationData?.totalAmount?.toFixed(2) || "0.00";

  // PDF download function
  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text("Quotation Details", 14, 20);

    const tableColumn = [
      "Scope",
      "Description",
      "Room(s)",
      "Area (sq ft)",
      "Rate per Sq Ft",
      "Amount",
    ];
    const tableRows = items.map((item) => [
      item.roomScope,
      item.description,
      item.roomType,
      item.areaSqft,
      item.ratePerSqft,
      item.amount.toFixed(2),
    ]);

    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
    });

    doc.setFontSize(14);
    doc.text(`Total Amount: ${totalAmount}`, 14, doc.lastAutoTable.finalY + 10);
    doc.save("quotation_details.pdf");
  };

  // CSV download function
  const downloadCSV = () => {
    const csvHeader = [
      "Scope,Description,Room(s),Area (sq ft),Rate per Sq Ft,Amount",
    ];
    const csvRows = items.map(
      (item) =>
        `${item.roomScope},${item.description},${item.roomType},${
          item.areaSqft
        },${item.ratePerSqft},${item.amount.toFixed(2)}`
    );

    csvRows.push(`,,,,Total Amount,${totalAmount}`);
    const csvContent = [csvHeader, ...csvRows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "quotation_details.csv";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Handle menu open and close
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{ bgcolor: "#3f51b5", color: "#fff", textAlign: "center" }}
      >
        Quotation Details
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: 2, boxShadow: 3 }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: "#f0f4f7" }}>
                <TableCell sx={{ color: "#3f51b5", fontWeight: "bold" }}>
                  Scope
                </TableCell>
                <TableCell sx={{ color: "#3f51b5", fontWeight: "bold" }}>
                  Description
                </TableCell>
                <TableCell sx={{ color: "#3f51b5", fontWeight: "bold" }}>
                  Room(s)
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ color: "#3f51b5", fontWeight: "bold" }}
                >
                  Area (sq ft)
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ color: "#3f51b5", fontWeight: "bold" }}
                >
                  Rate per Sq Ft
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ color: "#3f51b5", fontWeight: "bold" }}
                >
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:hover": { bgcolor: "#f9f9f9" } }}
                >
                  <TableCell>{item.roomScope}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.roomType}</TableCell>
                  <TableCell align="right">{item.areaSqft}</TableCell>
                  <TableCell align="right">{item.ratePerSqft}</TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: "bold", color: "#388e3c" }}
                  >
                    {item.amount.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mt: 2,
            gap: 1,
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", color: "#666", fontSize: "0.9rem" }}
          >
            Total Amount:
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#388e3c" }}
          >
            {totalAmount}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={handleMenuOpen} color="secondary">
          <DownloadIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={openMenu} onClose={handleMenuClose}>
          <MenuItem
            onClick={() => {
              downloadPDF();
              handleMenuClose();
            }}
          >
            Download PDF
          </MenuItem>
          <MenuItem
            onClick={() => {
              downloadCSV();
              handleMenuClose();
            }}
          >
            Download CSV
          </MenuItem>
        </Menu>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApprovedQuotationForm;
