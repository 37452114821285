import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid"; // Import DataGrid from Material-UI
import { useQuery, useMutation, useQueryClient } from "react-query";
import LoadingSpinner from "../../ui/LoadingSpinner";
import {
  getStatusColor,
  projectStatuses,
} from "../../components/constants/constants";
import {
  fetchProjects,
  createProject,
  updateProject,
  deleteProject,
} from "../../services/projectApi";
import ProjectForm from "../../components/forms/ProjectForm";
import { useNavigate } from "react-router-dom";

const AllProjectsPage = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState("card");
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // State for menu
  const [selectedProject, setSelectedProject] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));

  // Fetch all projects
  const {
    data: projects = [],
    isLoading,
    error,
  } = useQuery("projects", fetchProjects, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  // Mutation for creating a new project
  const { mutate: addProject, isLoading: isCreating } = useMutation(
    createProject,
    {
      onSuccess: () => queryClient.invalidateQueries("projects"), // Refresh projects list after adding
    }
  );

  // Mutation for updating an existing project
  const { mutate: editProject, isLoading: isUpdating } = useMutation(
    updateProject,
    {
      onSuccess: () => queryClient.invalidateQueries("projects"), // Refresh projects list after updating
    }
  );

  // Mutation for deleting (canceling) a project
  const { mutate: removeProject, isLoading: isdeleting } = useMutation(
    deleteProject,
    {
      onSuccess: () => queryClient.invalidateQueries("projects"), // Refresh projects list after deletion
    }
  );

  // Check for both query and mutation loading states
  if (isLoading || isCreating || isUpdating || isdeleting) {
    return <LoadingSpinner />;
  }

  // Handle Dialog open/close
  const handleOpenDialog = (edit = false, project = null) => {
    setIsEdit(edit);
    if (edit && project) {
      setSelectedProject({
        ...project,
        customerId: project.customer.id, // Ensure customer ID is correctly set for editing
      });
    } else {
      setSelectedProject({
        projectName: "",
        startDate: "",
        estimatedEndDate: "",
        budget: "",
        notes: "",
        customerId: "",
        projectStatus: "PENDING",
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  // Handle form submission for creating/updating a project
  const handleSubmit = (projectData) => {
    if (isEdit) {
      if (projectData) {
        editProject(projectData);
      }
    } else {
      addProject({
        ...projectData,
        companyId: user?.companyId,
      });
    }
    handleCloseDialog();
  };

  const handleViewDetails = (projectId) => {
    navigate(`/projects/details/${projectId}`);
  };

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  // Action menu handlers
  const handleOpenMenu = (event, project) => {
    setAnchorEl(event.currentTarget);
    setSelectedProject(project);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    handleOpenDialog(true, selectedProject);
    handleCloseMenu();
  };

  const handleCancel = () => {
    if (
      window.confirm(
        `Are you sure you want to cancel ${selectedProject.projectName}?`
      )
    ) {
      removeProject(selectedProject.id);
    }
    handleCloseMenu();
  };

  const getStatusLabel = (value) => {
    const status = projectStatuses.find((status) => status.value === value);
    return status ? status.label : value;
  };

  const columns = [
    {
      field: "projectName",
      headerName: "Project Name",
      flex: 3,
      renderCell: (params) => (
        <Button onClick={() => handleViewDetails(params.row.id)}>
          {params.value}
        </Button>
      ),
    },
    {
      field: "customer",
      headerName: "Customer",
      flex: 2,
      renderCell: (params) => (
        <span>{params?.row?.customer ? params.row.customer.name : "N/A"}</span>
      ),
    },
    { field: "startDate", headerName: "Start Date", flex: 2 },
    { field: "estimatedEndDate", headerName: "End Date", flex: 2 },
    {
      field: "projectStatus",
      headerName: "Status",
      flex: 2,
      renderCell: (params) => (
        <Chip
          label={getStatusLabel(params.value)}
          sx={{ backgroundColor: getStatusColor(params.value), color: "#fff" }}
        />
      ),
    },
    {
      field: "budget",
      headerName: "Budget",
      flex: 2,
      valueFormatter: ({ value }) =>
        value != null ? `₹${value.toLocaleString()}` : "N/A",
      renderCell: (params) => (
        <span>
          {params?.row?.budget
            ? `${params?.row?.budget.toLocaleString()}`
            : "N/A"}
        </span>
      ),
    },
    { field: "notes", headerName: "Notes", flex: 2 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.projectStatus !== "CANCELED" && (
            <IconButton onClick={(e) => handleOpenMenu(e, params.row)}>
              <MoreVertIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const rows = projects.map((project) => ({
    id: project.id,
    projectName: project.projectName,
    customer: project.customer,
    startDate: project.startDate,
    estimatedEndDate: project.estimatedEndDate,
    projectStatus: project.projectStatus,
    budget: project.budget,
    notes: project.notes,
  }));

  if (isLoading) return <LoadingSpinner />;
  if (error)
    return (
      <Typography color="error">
        Failed to load projects: {error.message}
      </Typography>
    );

  return (
    <Box sx={{ padding: 3 }}>
      {/* Title, Add Project Button, and View Mode Toggle */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#555" }}>
          All Projects
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog(false)}
            startIcon={<AddIcon />}
          >
            Add New Project
          </Button>
          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewModeChange}
            aria-label="view mode"
            sx={{ marginLeft: 2 }}
          >
            <ToggleButton value="list" aria-label="list view">
              <ViewListIcon />
            </ToggleButton>
            <ToggleButton value="card" aria-label="card view">
              <ViewModuleIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>

      {/* Card View */}
      {viewMode === "card" && (
        <Grid container spacing={3}>
          {projects.map((project) => (
            <Grid item xs={12} sm={6} md={4} key={project.id}>
              <Card
                sx={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", padding: 2 }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: "bold",
                      color: "#333",
                      cursor: "pointer",
                    }}
                    onClick={() => handleViewDetails(project.id)}
                  >
                    <Button
                      sx={{ fontWeight: "bold", fontSize: "15px" }}
                      onClick={() => handleViewDetails(project.id)}
                    >
                      {project.projectName}
                    </Button>
                  </Typography>

                  {/* Hide action button if project is canceled */}
                  {project.projectStatus !== "CANCELED" && (
                    <IconButton
                      sx={{ position: "absolute", right: 10, top: 10 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOpenMenu(e, project);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  )}

                  <Typography
                    variant="body2"
                    sx={{ color: "#666" }}
                    gutterBottom
                  >
                    <strong>Client: </strong>
                    {project.customer.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    <strong>Start Date: </strong>
                    {project.startDate}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    <strong>End Date: </strong>
                    {project.estimatedEndDate}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    <strong>Budget: </strong>₹{project.budget.toLocaleString()}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#666" }}>
                    <strong>Notes: </strong>
                    {project?.notes}
                  </Typography>
                  <Chip
                    label={getStatusLabel(project.projectStatus)}
                    sx={{
                      mt: 2,
                      backgroundColor: getStatusColor(project.projectStatus),
                      color: "#fff",
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* List View with DataGrid */}
      {viewMode === "list" && (
        <Box sx={{ overflowX: "auto" }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            disableSelectionOnClick
            sortingOrder={["asc", "desc"]}
            getRowHeight={() => "auto"}
          />
        </Box>
      )}

      {/* Action Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleEdit}>Edit Project</MenuItem>
        <MenuItem onClick={handleCancel} sx={{ color: "red" }}>
          Cancel Project
        </MenuItem>
      </Menu>

      {/* Add/Edit Project Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{isEdit ? "Edit Project" : "Add New Project"}</DialogTitle>
        <DialogContent>
          <ProjectForm
            projectInfo={selectedProject}
            setProjectInfo={setSelectedProject}
            isEdit={isEdit}
            onSubmit={handleSubmit}
            onCancel={handleCloseDialog} // Close dialog on cancel
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AllProjectsPage;
