import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import notificationApi from "../services/notificationApi";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA7I_NkrBFIk1fSrFJFvvJvZcnYNpwbk1o",
  authDomain: "fair-sandbox-441314-n8.firebaseapp.com",
  projectId: "fair-sandbox-441314-n8",
  storageBucket: "fair-sandbox-441314-n8.firebasestorage.app",
  messagingSenderId: "760331517178",
  appId: "1:760331517178:web:fbd8f0cbff85edb416dd43",
  measurementId: "G-JF6KRHY0G4",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("public/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registered successfully:", registration);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
} else {
  console.error("Service Workers are not supported in this browser.");
}

export const generateToken = async (setTokenFound) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user || !user.id) {
      console.warn("User not logged in or userId missing.");
      setTokenFound(false);
      return;
    }

    const storedToken = localStorage.getItem("fcmToken");

    // Check if token is already available in local storage
    if (storedToken) {
      setTokenFound(true);
      return storedToken;
    }

    // Request notification permissions from the user
    const permission = await Notification.requestPermission();
    console.log("permission", permission);
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BEaZuM76UfKW5DWX1L6_LIAPgycQg8Hl1DBJ3ILMUmNSGGt1elUS4t28QS4Tky1ZupMeORNCJ4bwFnruIN9CSTE",
      });

      if (token) {
        localStorage.setItem("fcmToken", token);
        setTokenFound(true);

        await notificationApi.registerDeviceToken({
          userId: user.id,
          token,
        });
        return token;
      } else {
        console.warn("No token generated.");
        setTokenFound(false);
      }
    } else {
      console.warn("Notification permission not granted.");
      setTokenFound(false);
    }
  } catch (error) {
    console.error("Error generating token:", error);
    setTokenFound(false);
  }
};
