import { useEffect, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  FormHelperText,
  Grid,
} from "@mui/material";
import { projectStatuses } from "../../components/constants/constants"; // Import project statuses
import { fetchCustomers } from "../../services/customerApi"; // Assuming you have an API to fetch customers

const ProjectForm = ({
  projectInfo,
  setProjectInfo,
  onSubmit,
  isEdit,
  onCancel,
}) => {
  const [customers, setCustomers] = useState([]); // Stores customer options
  const [isLoading, setIsLoading] = useState(false); // Loading state for customers
  const [hasLoaded, setHasLoaded] = useState(false); // Tracks whether customers are loaded
  const [errors, setErrors] = useState({}); // Validation errors

  // Set customerId in the state based on the projectInfo when editing
  useEffect(() => {
    if (projectInfo.customer && projectInfo.customer.id) {
      setProjectInfo((prev) => ({
        ...prev,
        customerId: projectInfo.customer.id,
      }));
    }
  }, [projectInfo.customer, setProjectInfo]);

  // Handle input changes for the project form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProjectInfo((prev) => ({ ...prev, [name]: value }));
  };

  // Load customers when the dropdown is clicked
  const handleCustomerDropdownOpen = async () => {
    if (!hasLoaded) {
      setIsLoading(true);
      try {
        const fetchedCustomers = await fetchCustomers();
        setCustomers(fetchedCustomers);
        setHasLoaded(true);
      } catch (error) {
        console.error("Error loading customers:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Handle customer selection change
  const handleCustomerChange = (e) => {
    const { value } = e.target;
    setProjectInfo((prev) => ({ ...prev, customerId: value }));
  };

  // Validation function
  const validateForm = () => {
    let formErrors = {};
    if (!projectInfo.projectName)
      formErrors.projectName = "Project Name is required";
    if (!projectInfo.customerId) formErrors.customerId = "Customer is required";
    if (!projectInfo.startDate) formErrors.startDate = "Start Date is required";
    if (!projectInfo.estimatedEndDate)
      formErrors.estimatedEndDate = "End Date is required";
    if (!projectInfo.budget || projectInfo.budget <= 0)
      formErrors.budget = "Valid Budget is required";

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0; // Return true if no errors
  };

  // Handle form submission
  const handleSubmit = () => {
    if (validateForm()) {
      onSubmit(projectInfo); // Pass `projectInfo` to the onSubmit handler
    }
  };

  return (
    <Box sx={{ padding: 2, maxWidth: "600px", margin: "0 auto" }}>
      {/* Project Name - Full Row */}
      <TextField
        label="Project Name"
        name="projectName"
        fullWidth
        variant="standard"
        value={projectInfo.projectName || ""}
        onChange={handleInputChange}
        required
        error={!!errors.projectName}
        helperText={errors.projectName}
        sx={{ marginBottom: 2 }}
      />

      {/* Customer - Full Row */}
      <FormControl
        fullWidth
        sx={{ marginBottom: 2 }}
        error={!!errors.customerId}
      >
        <InputLabel>Customer</InputLabel>
        <Select
          value={projectInfo.customerId || ""}
          label="Customer"
          variant="standard"
          onChange={handleCustomerChange}
          onOpen={handleCustomerDropdownOpen} // Load customers when the dropdown opens
          required
        >
          {/* Display the currently selected customer */}
          {projectInfo.customer && (
            <MenuItem value={projectInfo.customer.id}>
              {projectInfo.customer.name}
            </MenuItem>
          )}

          {/* Show other customers only when the dropdown is opened */}
          {customers.map((customer) => (
            <MenuItem key={customer.id} value={customer.id}>
              {customer.name}
            </MenuItem>
          ))}
        </Select>
        {isLoading && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
            <CircularProgress size={20} />
          </Box>
        )}
        <FormHelperText>{errors.customerId}</FormHelperText>
      </FormControl>

      {/* Two-Column Layout */}
      <Grid container spacing={2}>
        {/* Start Date */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Start Date"
            name="startDate"
            type="date"
            fullWidth
            variant="standard"
            value={projectInfo.startDate || ""}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            required
            error={!!errors.startDate}
            helperText={errors.startDate}
          />
        </Grid>

        {/* End Date */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="End Date"
            name="estimatedEndDate"
            type="date"
            fullWidth
            variant="standard"
            value={projectInfo.estimatedEndDate || ""}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            required
            error={!!errors.estimatedEndDate}
            helperText={errors.estimatedEndDate}
          />
        </Grid>

        {/* Budget */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Budget"
            name="budget"
            type="number"
            fullWidth
            variant="standard"
            value={projectInfo.budget || ""}
            onChange={handleInputChange}
            required
            error={!!errors.budget}
            helperText={errors.budget}
          />
        </Grid>

        {/* Status */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              name="projectStatus"
              value={projectInfo.projectStatus}
              onChange={handleInputChange}
              required
              variant="standard"
              disabled={!isEdit} // Disable in create mode
            >
              {projectStatuses.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Notes Input */}
      <TextField
        label="Notes"
        name="notes"
        multiline
        rows={2}
        fullWidth
        variant="standard"
        value={projectInfo.notes || ""}
        onChange={handleInputChange}
        sx={{ marginTop: 1 }}
      />

      {/* Action Buttons */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 3 }}>
        <Button
          onClick={onCancel}
          color="error"
          sx={{ fontWeight: "bold", padding: "10px 20px" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ fontWeight: "bold", padding: "10px 20px" }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectForm;
