import apiClient from "./apiClient";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

// Fetch all quotations for a project
export const fetchQuotationsByProject = async (projectId) => {
  const response = await apiClient.get(
    `${API_BASE_URL}/quotations/project/${projectId}`
  );
  return response.data.data;
};

// Fetch details of a specific quotation
export const fetchQuotationById = async (quotationId) => {
  const response = await apiClient.get(
    `${API_BASE_URL}/quotations/${quotationId}`
  );
  return response.data.data;
};
