import apiClient from "./apiClient";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

/**
 * Fetch all roles
 */
export const fetchAllRoles = async () => {
  const response = await apiClient.get(`${API_BASE_URL}/roles`);
  return response.data.data; // Assuming roles are returned in the `data` key
};

/**
 * Fetch a role by ID
 * @param {string} roleId - UUID of the role
 */
export const fetchRoleById = async (roleId) => {
  const response = await apiClient.get(`${API_BASE_URL}/roles/${roleId}`);
  return response.data.data; // Assuming the role is returned in the `data` key
};

/**
 * Create a new role
 * @param {Object} role - Role object containing role details
 */
export const createRole = async (role) => {
  const response = await apiClient.post(`${API_BASE_URL}/roles`, role);
  return response.data.data; // Assuming the created role is returned in the `data` key
};

/**
 * Update an existing role
 * @param {string} roleId - UUID of the role to be updated
 * @param {Object} role - Role object containing updated role details
 */
export const updateRole = async (roleId, role) => {
  const response = await apiClient.put(`${API_BASE_URL}/roles/${roleId}`, role);
  return response.data.data; // Assuming the updated role is returned in the `data` key
};

/**
 * Delete a role by ID
 * @param {string} roleId - UUID of the role to be deleted
 */
export const deleteRole = async (roleId) => {
  const response = await apiClient.delete(`${API_BASE_URL}/roles/${roleId}`);
  return response.data.data; // Assuming the deletion success status is returned in the `data` key
};
